export enum MessageSubject {
  OSINTSearch = 'osintSearch',
  TextAnalysisCaregoriesStats = 'textAnalysis.categoriesStats.request',
  TextAnalysisNamedEntityDetails = 'textAnalysis.namedEntityDetails.request',
  TextAnalysisNamedEntity = 'textAnalysis.namedEntities.request',
  DeepOsintInitiateQuery = 'deepOsint.query.request',
  DeepOsintCompletedResponse = 'deepOsint.query.response',
  DeepOsintTopConnections = 'deepOsint.topConnections.request',
  DeepOsintTopConnectionsResponse = 'deepOsint.topConnections.response',
  DeepOsintFamilyMembers = 'deepOsint.familyMembers.request',
  DeepOsintTaggedProfiles = 'deepOsint.taggedProfiles.request',
  DeepOsintPhotoLikers = 'deepOsint.photoLikers.request',
  DeepOsintPhotoCommenters = 'deepOsint.photoCommenters.request',
  DeepOsintPostLikers = 'deepOsint.postLikers.request',
  DeepOsintPostCommenters = 'deepOsint.postCommenters.request',
  DeepOsintPhotoShares = 'deepOsint.photoShares.request',
  DeepOsintPostShares = 'deepOsint.postShares.request',
  DeepOsintMutualFriendsRequest = 'deepOsint.mutualFriends.request',
  DeepOsintMutualFriendsResponse = 'deepOsint.mutualFriends.response',
  DeepOsintPhotoDetailsRequest = 'deepOsint.photoDetails.request',
  DeepOsintPhotoDetailsResponse = 'deepOsint.photoDetails.response',
  DeepOsintPostDetailsRequest = 'deepOsint.postDetails.request',
  DeepOsintPostDetailsResponse = 'deepOsint.postDetails.response',
  CommonRelationProfilesRequest = 'commonRelation.profiles.request',
  CommonRelationProfilesResponse = 'commonRelation.profiles.response',
  CommonRelationWorkplacesRequest = 'commonRelation.workplaces.request',
  CommonRelationWorkplacesResponse = 'commonRelation.workplaces.response',
  CommonRelationEducationRequest = 'commonRelation.education.request',
  CommonRelationEducationResponse = 'commonRelation.education.response',
  CommonRelationFacesRequest = 'commonRelation.faces.request',
  CommonRelationFacesResponse = 'commonRelation.faces.response',
  SomedusQueryRequest = 'somedus.query.request',
  SomedusQueryResponse = 'somedus.query.response',
  NearbyLocations = 'nearby.locations',
  TrilaterationNearbyLocations = 'trilateration.nearby.locations',
  AvatarUpdate = 'avatar.avatarUpdate',
  AvatarRemoteViewEnded = 'avatar.avatarRemoteViewEnded',
  AvatarRemoteViewStarted = 'avatar.avatarRemoteViewStarted',
  AvatarExpiring = 'avatar.avatarExpiring',
  Recommendations = 'recommendations.locationHistory.request',
  importRecommendations = 'import.locationHistory.request',
  searchingTrgDataLake = 'notify.searching_trg_data_lake',
  RadicalMonitoringRecord = 'radicalMonitoring.record',
  NotifySearchingSocialProfiles = 'notify.searching_social_profiles',
  NotifySearchingInstantMessageProfiles = 'notify.searching_instant_message_profiles',
  NotifySearchingDarkWeb = 'notify.searching_dark_web',
  NotifySearchingOpenWeb = 'notify.searching_open_web',
  NotifySearchingClosedDatabases = 'notify.searching_closed_databases',
  NotifySearchingSocialDatabases = 'notify.searching_social_databases',
  NotifyAnalysingResults = 'notify.analyzing_results',
  UpdateLedgerDetails = 'update.ledgerDetails'
}
