import { BaseDTO } from 'datalayer/services/base';
import { Media } from 'datalayer/models/media/media';
import { EntityType, DataSource } from 'datalayer/models/platform-models';

export class MediaDTO extends BaseDTO<Media, MediaDTO> {

  private id: any;
  private url?: string;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private title: string;
  private description: string;
  private album_id?: string;
  private content: string;
  private source_url: string;
  private source_entity?: {
    id: string,
    created_at: Date,
    parent_id: string;
  };
  private target_id?: string;
  private count_likes?: number;
  private count_shares?: number;
  public count_views?: number;
  public count_comments?: number;
  public duration?: number;
  public muted?: boolean;
  public hashtags?: string[];
  public mention_type?: string;
  public published_at?: Date;
  public sub_source?: string;

  constructor(model?: Partial<MediaDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Media): MediaDTO {
    this.id = model.id;
    this.url = model.url;
    this.type = model.type;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.source = model.source;
    this.target_id = model.targetId;
    this.title = model.title;
    this.description = model.description;
    this.content = model.content;
    this.source_url = model.sourceUrl;
    this.count_likes = model.countLikes;
    this.count_shares = model.countShares;
    this.count_views = model.countViews;
    this.count_comments = model.countComments;
    this.duration = model.duration;
    this.muted = model.muted;
    this.hashtags = model.hashtags;
    this.mention_type = model.mentionType;
    this.published_at = model.publishedAt;
    this.album_id = model.albumId;
    this.sub_source = model.subSource;

    return this;
  }

  toModel(): Media {
    const profile: Media = new Media({
      id: this.id,
      url: this.url,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      title: this.title,
      description: this.description,
      content: this.content,
      sourceUrl: this.source_url,
      countLikes: this.count_likes,
      countShares: this.count_shares,
      countComments: this.count_comments,
      countViews: this.count_views,
      duration: this.duration,
      muted: this.muted,
      hashtags: this.hashtags,
      mentionType: this.mention_type,
      publishedAt: this.published_at,
      albumId: this.album_id,
      subSource: this.sub_source,
    });

    return profile;
  }

}
