import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LandingPageGuard implements CanActivate {
  constructor(private router: Router, private appConfigService: AppConfigService) {}

  canActivate(): Observable<boolean> | boolean {
    const isCDROnlyEnabled = this.appConfigService.getConfigVariable('isCDROnlyEnabled');
    if (isCDROnlyEnabled) {
      this.router.navigate(['core']);
    } else if (this.appConfigService.getConfig()?.enabledLandingScreen) {
      this.router.navigate(['webint']);
    } else {
      this.router.navigate(['discovery']);
    }
    return true;
  }
}
