import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlMomentDateTimeModule,
  OWL_DATE_TIME_FORMATS
} from '@busacca/ng-pick-datetime';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Angulartics2Module } from 'angulartics2';
import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NouisliderModule } from 'ng2-nouislider';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AvatarModule } from 'ngx-avatar';
import { ColorCircleModule } from 'ngx-color/circle';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { DaySelectorComponent } from 'src/app/components/analytics/day-time-selector/day-selector/day-selector.component';
import { DayTimeSelectorComponent } from 'src/app/components/analytics/day-time-selector/day-time-selector.component';
import { DatetimepickerComponent } from 'src/app/components/datetimepicker/datetimepicker.component';
import { DefaultIntl } from 'src/app/components/datetimepicker/keys-translator';
import { IntelFiltersComponent } from 'src/app/components/intel-filters/intel-filters.component';
import { RoundNumberPipe } from 'src/app/components/location-log/round-number-pipe';
import { CustomToolboxMarkerComponent } from 'src/app/components/map/custom-toolbox-marker/custom-toolbox-marker.component';
import { SearchIntelNavbarComponent } from 'src/app/components/search-intel-navbar/search-intel-navbar.component';
import { DndDirective } from 'src/app/components/searchby-image/dnd.directive';
import { SearchbyImageComponent } from 'src/app/components/searchby-image/searchby-image.component';
import { MaterialModule } from 'src/app/material.module';
import { TargetModalComponent } from 'src/app/modules/ad-ids/ad-ids-map/target-modal/target-modal.component';
import { HighlightSearchPipe } from 'src/app/modules/profiler/components/profiler-radical-monitoring/profiler-radical-monitoring-highlight-match';
import { TargetFormComponent } from 'src/app/modules/profiler/components/target-form/target-form.component';
import { TargetListItemComponent } from 'src/app/modules/profiler/components/target-list-item/target-list-item.component';
import { TimelineComponent } from 'src/app/modules/profiler/components/timeline/timeline.component';
import { CopyClipboardDirective } from 'src/app/modules/profiler/shared/copy-clipboard/copy-clipboard.directive';
import { DoughnutChartComponent } from 'src/app/modules/profiler/shared/doughnut-chart/doughnut-chart.component';
import { MinimalistChartComponent } from 'src/app/modules/profiler/shared/minimalist-chart/minimalist-chart.component';
import { TargetDialogComponent } from 'src/app/modules/search-intel/components/target-dialog/target-dialog.component';
import { SearchIntelLoader } from 'src/app/modules/search-intel/shared/search-intel-loader/search-intel-loader.component';
import { ExtraLocationInformationDialogComponent } from 'src/app/modules/trg-common/components/extra-location-information-dialog/extra-location-information-dialog.component';
import { CapitalizePipe } from 'src/app/pipes/capitalize.pipe';
import { CountryCodePipe, CountryPipe } from 'src/app/pipes/country.pipe';
import { ExclusionPipe } from 'src/app/pipes/exclusion.pipe';
import { HtmlToTextPipe } from 'src/app/pipes/html-to-text.pipe';
import { IncludesPipe } from 'src/app/pipes/includes.pipe';
import { MeterToKilometerPipe } from 'src/app/pipes/meter-to-kilometer.pipe';
import { TransferDataPipe } from 'src/app/pipes/transfer-data.pipe';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { BaseModalComponentComponent } from 'src/app/shared/components/base-modal-component/base-modal-component.component';
import { CardComponent } from 'src/app/shared/components/card/card.component';
import { ClUploadComponent } from 'src/app/shared/components/cl-upload/cl-upload.component';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { CostInfoActionDialogComponent } from 'src/app/shared/components/cost-info-action-dialog/cost-info-action-dialog.component';
import { CostInfoDialogComponent } from 'src/app/shared/components/cost-info-dialog/cost-info-dialog.component';
import { CreateTargetDialogComponent } from 'src/app/shared/components/create-target-dialog/create-target-dialog.component';
import { CreditCostButtonComponent } from 'src/app/shared/components/credit-cost-button/credit-cost-button.component';
import { DeepOsintNotificationComponent } from 'src/app/shared/components/deep-osint-notification/deep-osint-notification.component';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { ExpiredDialogComponent } from 'src/app/shared/components/expired-dialog/expired-dialog.component';
import { ExtractPeerDialogComponent } from 'src/app/shared/components/extract-peer-dialog/extract-peer-dialog.component';
import { FeatureGroupNotificationsComponent } from 'src/app/shared/components/feature-group-notifications/feature-group-notifications.component';
import { GroupedCheckboxesComponent } from 'src/app/shared/components/grouped-checkboxes/grouped-checkboxes.component';
import { HeaderContentFooterDialogComponent } from 'src/app/shared/components/header-content-footer-dialog/header-content-footer-dialog.component';
import { ImageOverlayComponent } from 'src/app/shared/components/image-overlay/image-overlay.component';
import { IntelLoaderComponent } from 'src/app/shared/components/intel-loader/intel-loader.component';
import { IntelSearchImagePopupComponent } from 'src/app/shared/components/intel-search-image-popup/intel-search-image-popup.component';
import { LightboxComponentComponent } from 'src/app/shared/components/lightbox-component/lightbox-component.component';
import { MultiStageLoaderComponent } from 'src/app/shared/components/multi-stage-loader/multi-stage-loader.component';
import { MultipleAvatarsComponent } from 'src/app/shared/components/multiple-avatars/multiple-avatars.component';
import { NewRequestClDialogComponent } from 'src/app/shared/components/new-request-cl-dialog/new-request-cl-dialog.component';
import { OsintGroupNotificationsComponent } from 'src/app/shared/components/osint-group-notifications/osint-group-notifications.component';
import { OtpSelectionRadioComponent } from 'src/app/shared/components/otp-selection-radio/otp-selection-radio.component';
import { PlainProgressLoaderComponent } from 'src/app/shared/components/plain-progress-loader/plain-progress-loader.component';
import { PredictedLocationsFormComponent } from 'src/app/shared/components/predicted-locations-form/predicted-locations-form.component';
import { ProgressLoaderComponent } from 'src/app/shared/components/progress-loader/progress-loader.component';
import { PurchaseInfoDialogComponent } from 'src/app/shared/components/purchase-info-dialog/purchase-info-dialog.component';
import { QuestionnaireDialogComponent } from 'src/app/shared/components/questionnaire-dialog/questionnaire-dialog.component';
import { RedirectSnackBarComponent } from 'src/app/shared/components/redirect-snack-bar/redirect-snack-bar.component';
import { RequestClDialogComponent } from 'src/app/shared/components/request-cl-dialog/request-cl-dialog.component';
import { RequestClFormComponent } from 'src/app/shared/components/request-cl-form/request-cl-form.component';
import { ScheduleActivationDialogComponent } from 'src/app/shared/components/schedule-activation-dialog/schedule-activation-dialog.component';
import { SelectSorterComponent } from 'src/app/shared/components/select-sorter/select-sorter.component';
import { SpinnerLoaderComponent } from 'src/app/shared/components/spinner-loader/spinner-loader.component';
import { TableContentLoaderComponent } from 'src/app/shared/components/table-content-loader/table-content-loader.component';
import { TargetImgComponent } from 'src/app/shared/components/target-img/target-img.component';
import { UploadFileSelectorComponent } from 'src/app/shared/components/upload-file-selector/upload-file-selector.component';
import { ButtonSpinnerDirective } from 'src/app/shared/directives/button-spinner.directive';
import { ClickOutsideDirective } from 'src/app/shared/directives/clickOutside/clickOutside.directive';
import { DebounceClickDirective } from 'src/app/shared/directives/debounce-click/debounce-click.directive';
import { IsEllipsisActiveDirective } from 'src/app/shared/directives/elipsis.directive';
import { FallbackImgDirective } from 'src/app/shared/directives/fallback-img.directive';
import { ImageOverlayDirective } from 'src/app/shared/directives/image-overlay.directive';
import { RepeatDirective } from 'src/app/shared/directives/repeat.directive';
import { ReportAttributeDirective } from 'src/app/shared/directives/report-attribute.directive';
import { AccessControlRestrictedDirective } from 'src/app/shared/directives/roles-manager.directive';
import { ScrollIntoViewDirective } from 'src/app/shared/directives/scroll-into-view/scroll-into-view.directive';
import { StopClickPropagationDirective } from 'src/app/shared/directives/stop-click-propagation.directive';
import { AnimateDirective } from 'src/app/shared/directives/trigger-animation.directive';
import { ClCalendarValueToOpacityPipe } from 'src/app/shared/modules/call-logs-shared/pipes/cl-calendar-value-to-opacity.pipe';
import { SortByDayPipe } from 'src/app/shared/modules/call-logs-shared/pipes/sort-by-day.pipe';
import { SortByTimePipe } from 'src/app/shared/modules/call-logs-shared/pipes/sort-by-time.pipe';
import { CoreToastrModule } from 'src/app/shared/modules/core-toastr/core-toastr.module';
import { CreateInvestigationComponent } from 'src/app/shared/modules/create-investigation/create-investigation/create-investigation.component';
import { MapArrayToPropPipe } from 'src/app/shared/pipes/map-array-to-prop.pipe';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { VisualTimelineEventTypePipe } from 'src/app/shared/pipes/visual-timeline-event-type.pipe';
import { JoinPipe } from '../../pipes/join.pipe';
import { ClExportBaseComponent } from '../call-logs/components/cl-actions/cl-export-base/cl-export-base.component';
import { PurchaseNotificationComponent } from '../call-logs/components/cl-main/components/cl-intel-suggestions/purchase-notification/purchase-notification.component';
import { CreateCaseDialogComponent } from '../profiler/components/create-case-dialog/create-case-dialog.component';
import { TargetDeleteComponent } from '../profiler/components/target-delete/target-delete.component';
import { IntelResultsNotificationComponent } from '../search-intel/components/intel-results-notification/intel-results-notification.component';
import { EnhanceProfilePopupComponent } from '../search-intel/shared/enhance-profile-popup/enhance-profile-popup.component';
import { MsisdnConfirmationPopupComponent } from '../search-intel/shared/msisdn-confirmation-popup/msisdn-confirmation-popup.component';
import { MultiSelectChipComponent } from '../search-intel/shared/multi-select-chip/multi-select-chip.component';
import { ActivateOtpCodeInputComponent } from './../../shared/components/activate-otp-code-input/activate-otp-code-input.component';
import { ActivateQrOtpCodeInputComponent } from './../../shared/components/activate-qr-otp-code-input/activate-qr-otp-code-input.component';
import { InvestigationFeedbackPopupComponent } from './../../shared/components/investigation-feedback-popup/investigation-feedback-popup.component';
import { WebintDisabledModalComponent } from './../../shared/components/webint-disabled-modal/webint-disabled-modal.component';
import { TooltipModule } from './../../shared/modules/tooltip/tooltip.module';
import { TrgCommonUiModule } from './../trg-common-ui/trg-common-ui.module';
import { ActivityPatternComponent } from './components/activity-pattern/activity-pattern.component';
import { GenericActivityCellComponent } from './components/activity-pattern/components/generic-activity-cell/generic-activity-cell.component';
import { TemplateActivityCellComponent } from './components/activity-pattern/components/template-activity-cell/template-activity-cell.component';
import { TrgTemplateOutlet } from './directives/dynamic-template.directive';
import { CallLogPermittedPipe } from './pipes';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { FeedLineChartComponent } from '../profiler/shared/feed-line-chart/feed-line-chart.component';
import { ApxRadialBarChartComponent } from 'src/app/shared/components/apx-radialbar-chart/apx-radialbar-chart.component';
import { CreditDetailsComponent } from 'src/app/components/credit-pools-details/credit-details.component';
import { CreditPoolsGaugeComponent } from 'src/app/components/credit-pools-gauge/credit-pools-gauge.component';
import { CreditUsageBarComponent } from 'src/app/components/credit-pools-details/components/credit-usage-bar/credit-usage-bar.component';
import { CreditBarTooltipComponent } from 'src/app/components/credit-pools-details/components/credit-bar-tooltip/credit-bar-tooltip.component';
import { CreditUsageBulletsComponent } from 'src/app/components/credit-pools-details/components/credit-usage-bullets/credit-usage-bullets.component';
import { EntityDetailsDialogComponent } from 'src/app/shared/components/entity-details-dialog/entity-details-dialog.component';
import { SocialTooltipComponent } from './components/social-tooltip/social-tooltip.component';
import { InvestigationSocialPostComponent } from './components/investigation-social-post/investigation-social-post.component';
import { IpLocationTooltipComponent } from 'src/app/shared/components/ip-location-tooltip/ip-location-tooltip.component';
import { GeolocationImTooltipComponent } from 'src/app/components/geolocation-details/components/geolocation-im-tooltip/geolocation-im-tooltip.component';
import {
  FilterInvestigationDialogComponent
} from "../../shared/components/filter-investigation-dialog/filter-investigation-dialog.component";

const MY_MOMENT_FORMATS = {
  fullPickerInput: 'DD/MM/YYYY',
  monthYearLabel: 'MMM YYYY',
};

@NgModule({
  imports: [
    ScrollingModule,
    CommonModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    MaterialModule,
    FormsModule,
    SweetAlert2Module,
    NouisliderModule,
    Ng2TelInputModule,
    OwlMomentDateTimeModule,
    AvatarModule,
    NgChartsModule,
    ContentLoaderModule,
    ColorCircleModule,
    GooglePlaceModule,
    CoreToastrModule,
    Angulartics2Module,
    TrgCommonUiModule,
    TooltipModule,
    NgxFileDropModule,
    PipesModule,
    NgApexchartsModule,
  ],
  declarations: [
    CapitalizePipe,
    TransferDataPipe,
    JoinPipe,
    CallLogPermittedPipe,
    IncludesPipe,
    DatetimepickerComponent,
    TranslatePipe,
    MeterToKilometerPipe,
    HighlightSearchPipe,
    RoundNumberPipe,
    DayTimeSelectorComponent,
    DaySelectorComponent,
    CountryPipe,
    CountryCodePipe,
    ExclusionPipe,
    TargetListItemComponent,
    DoughnutChartComponent,
    CopyClipboardDirective,
    TimelineComponent,
    MinimalistChartComponent,
    CustomToolboxMarkerComponent,
    TimelineComponent,
    MinimalistChartComponent,
    DebounceClickDirective,
    ClickOutsideDirective,
    SearchIntelNavbarComponent,
    SearchIntelLoader,
    ProgressLoaderComponent,
    IntelLoaderComponent,
    PlainProgressLoaderComponent,
    RepeatDirective,
    TargetFormComponent,
    StopClickPropagationDirective,
    ButtonSpinnerDirective,
    TargetFormComponent,
    CardComponent,
    DialogComponent,
    CostInfoDialogComponent,
    SpinnerLoaderComponent,
    ConfirmationDialogComponent,
    ExtraLocationInformationDialogComponent,
    TargetModalComponent,
    HtmlToTextPipe,
    RedirectSnackBarComponent,
    OsintGroupNotificationsComponent,
    FeatureGroupNotificationsComponent,
    PurchaseNotificationComponent,
    SearchbyImageComponent,
    IntelFiltersComponent,
    DndDirective,
    IsEllipsisActiveDirective,
    DeepOsintNotificationComponent,
    AnimateDirective,
    ImageOverlayDirective,
    ImageOverlayComponent,
    MapArrayToPropPipe,
    FallbackImgDirective,
    MultipleAvatarsComponent,
    TargetDialogComponent,
    ReportAttributeDirective,
    GroupedCheckboxesComponent,
    IntelSearchImagePopupComponent,
    EnhanceProfilePopupComponent,
    ExpiredDialogComponent,
    MultiStageLoaderComponent,
    CostInfoActionDialogComponent,
    PurchaseInfoDialogComponent,
    AccessControlRestrictedDirective,
    SortByDayPipe,
    SortByTimePipe,
    ClCalendarValueToOpacityPipe,
    LightboxComponentComponent,
    BaseModalComponentComponent,
    SelectSorterComponent,
    HeaderContentFooterDialogComponent,
    TargetDeleteComponent,
    ClExportBaseComponent,
    CreateTargetDialogComponent,
    MsisdnConfirmationPopupComponent,
    CreateCaseDialogComponent,
    TableContentLoaderComponent,
    ScrollIntoViewDirective,
    CreditCostButtonComponent,
    TargetImgComponent,
    ActivityPatternComponent,
    RequestClDialogComponent,
    TrgTemplateOutlet,
    GenericActivityCellComponent,
    TemplateActivityCellComponent,
    ScheduleActivationDialogComponent,
    PredictedLocationsFormComponent,
    ExtractPeerDialogComponent,
    VisualTimelineEventTypePipe,
    MultiSelectChipComponent,
    InvestigationFeedbackPopupComponent,
    QuestionnaireDialogComponent,
    IntelResultsNotificationComponent,
    WebintDisabledModalComponent,
    OtpSelectionRadioComponent,
    ActivateOtpCodeInputComponent,
    ActivateQrOtpCodeInputComponent,
    NewRequestClDialogComponent,
    CreateInvestigationComponent,
    UploadFileSelectorComponent,
    ClUploadComponent,
    RequestClFormComponent,
    FeedLineChartComponent,
    ApxRadialBarChartComponent,
    CreditDetailsComponent,
    CreditPoolsGaugeComponent,
    CreditUsageBarComponent,
    CreditBarTooltipComponent,
    CreditUsageBulletsComponent,
    EntityDetailsDialogComponent,
    SocialTooltipComponent,
    InvestigationSocialPostComponent,
    IpLocationTooltipComponent,
    GeolocationImTooltipComponent,
    FilterInvestigationDialogComponent,
  ],
  exports: [
    CallLogPermittedPipe,
    CapitalizePipe,
    TransferDataPipe,
    JoinPipe,
    IncludesPipe,
    CustomToolboxMarkerComponent,
    DatetimepickerComponent,
    TranslatePipe,
    MeterToKilometerPipe,
    RoundNumberPipe,
    DayTimeSelectorComponent,
    DaySelectorComponent,
    SweetAlert2Module,
    NouisliderModule,
    Ng2TelInputModule,
    CountryPipe,
    CountryCodePipe,
    ExclusionPipe,
    OwlDateTimeModule,
    ScrollingModule,
    TargetListItemComponent,
    DoughnutChartComponent,
    NgChartsModule,
    CopyClipboardDirective,
    TimelineComponent,
    MinimalistChartComponent,
    ContentLoaderModule,
    ContentLoaderModule,
    TimelineComponent,
    MinimalistChartComponent,
    DebounceClickDirective,
    ClickOutsideDirective,
    SearchIntelNavbarComponent,
    SearchIntelLoader,
    ProgressLoaderComponent,
    IntelLoaderComponent,
    PlainProgressLoaderComponent,
    RepeatDirective,
    TargetFormComponent,
    ColorCircleModule,
    StopClickPropagationDirective,
    ButtonSpinnerDirective,
    CardComponent,
    DialogComponent,
    CostInfoDialogComponent,
    SpinnerLoaderComponent,
    ConfirmationDialogComponent,
    HtmlToTextPipe,
    RedirectSnackBarComponent,
    SearchbyImageComponent,
    IntelFiltersComponent,
    AvatarModule,
    HighlightSearchPipe,
    IsEllipsisActiveDirective,
    AnimateDirective,
    ImageOverlayDirective,
    ImageOverlayComponent,
    MapArrayToPropPipe,
    MultipleAvatarsComponent,
    ReportAttributeDirective,
    FallbackImgDirective,
    GroupedCheckboxesComponent,
    Angulartics2Module,
    ExpiredDialogComponent,
    MultiStageLoaderComponent,
    CostInfoActionDialogComponent,
    AccessControlRestrictedDirective,
    SortByDayPipe,
    SortByTimePipe,
    ClCalendarValueToOpacityPipe,
    LightboxComponentComponent,
    BaseModalComponentComponent,
    HeaderContentFooterDialogComponent,
    SelectSorterComponent,
    TargetDeleteComponent,
    ClExportBaseComponent,
    CreateTargetDialogComponent,
    MsisdnConfirmationPopupComponent,
    CreateCaseDialogComponent,
    TableContentLoaderComponent,
    ScrollIntoViewDirective,
    CreditCostButtonComponent,
    TargetImgComponent,
    ActivityPatternComponent,
    TooltipModule,
    RequestClDialogComponent,
    TrgTemplateOutlet,
    ScheduleActivationDialogComponent,
    PredictedLocationsFormComponent,
    ExtractPeerDialogComponent,
    VisualTimelineEventTypePipe,
    MultiSelectChipComponent,
    InvestigationFeedbackPopupComponent,
    ScheduleActivationDialogComponent,
    WebintDisabledModalComponent,
    OtpSelectionRadioComponent,
    ActivateOtpCodeInputComponent,
    ActivateQrOtpCodeInputComponent,
    NewRequestClDialogComponent,
    CreateInvestigationComponent,
    UploadFileSelectorComponent,
    ClUploadComponent,
    RequestClFormComponent,
    FeedLineChartComponent,
    ApxRadialBarChartComponent,
    CreditDetailsComponent,
    CreditPoolsGaugeComponent,
    CreditUsageBarComponent,
    CreditBarTooltipComponent,
    CreditUsageBulletsComponent,
    EntityDetailsDialogComponent,
    SocialTooltipComponent,
    InvestigationSocialPostComponent,
    IpLocationTooltipComponent,
    GeolocationImTooltipComponent,
    FilterInvestigationDialogComponent,
  ],
  providers: [
    TranslatePipe,
    RoundNumberPipe,
    HighlightSearchPipe,
    CountryPipe,
    MeterToKilometerPipe,
    {
      provide: OwlDateTimeIntl,
      useFactory: (appConfigService: AppConfigService) => new DefaultIntl(appConfigService.getLanguage()),
      deps: [AppConfigService]
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ],
})
export class TrgCommonModule { }
