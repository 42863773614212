import { EntityType } from '@trg-commons/data-models-ts';
import { Link as AnalysisLink, Node as AnalysisNode } from '@trg-ui/link-analysis';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { LinkAnalysisService } from '../../link-analysis/services/link-analysis.service';
import {
  GlyphSize,
  LabelSize,
  nodeSize,
  nodeTypes,
} from '../../link-analysis/shared/link-analysis.model';
import { InvestigationStylesService } from '../services/investigation-styles.service';
import { RawEdge, RawNode, GraphServiceResponse } from './graph-entities.model';

export class GraphEntitiesDto implements GraphServiceResponse {
  nodes: RawNode[];
  edges: RawEdge[];
  entityId: string;

  constructor(
    model: Partial<GraphEntitiesDto>,
    protected linkAnalysisService: LinkAnalysisService,
    protected investigationStyleService: InvestigationStylesService,
    private translationService: TranslationService
  ) {
    if (model) {
      Object.assign(this, model);
    }
  }

  toModel(): { [key: string]: AnalysisNode | AnalysisLink } {
    let output: { [key: string]: AnalysisNode | AnalysisLink } = {};

    this.nodes.forEach((node) => {
      let newNode = this.createNodeByType(node);
      if (!newNode) {
        return;
      }
      output[node.id] = newNode as AnalysisNode | AnalysisLink;
    });

    this.edges.forEach((edge, index: number) => {
      // Edges as far as i can see don't have source property. Getting it from nodes.
      // First node is the actual Facebook Entity so skiping to index+1
      const source = this.linkAnalysisService.parsePlatform(this.nodes[index+1].source);
      let newEdge = this.linkAnalysisService.getLink(
        `${this.entityId}@${source}`,
        edge.toEntity.id,
        this.linkAnalysisService.parseRelation(edge.relationType),
        false,
        `#${this.investigationStyleService.getColorById(this.entityId + '@' + source)}`,
      );
      if (!newEdge) {
        return;
      }
      output[newEdge.id] = newEdge as AnalysisLink;
    });
    return output;
  }

  private createNodeByType(node: RawNode): AnalysisNode {
    let newNode;
    switch (node.type) {
      case EntityType.Post: {
        newNode = this.createFacebookPostNode(node);
        break;
      }
      default: {
        console.warn('node.type not found for node: ', node);
      }
    }
    return newNode;
  }

  private createFacebookPostNode(node: RawNode): AnalysisNode {
    const data = {
      text: node.title || node.body,
      url: node.url,
      date: node.createdAt ? new Date(node.createdAt) : null,
      node,
      type: EntityType.Post,
    };
    const platform = this.linkAnalysisService.parsePlatform(node.source);
    return this.linkAnalysisService.createNewNode(
      node.id,
      `#${this.investigationStyleService.getColorById(this.entityId + '@' + platform)}`,
      this.translationService.translate(node.type),
      { ...data, type: nodeTypes.POST },
      nodeSize.XSMALL,
      [this.linkAnalysisService.getGlyph(`link-analysis/${platform}.svg`, 120, false, GlyphSize.SMALL)],
      LabelSize.SMALL
    );
  }
}
