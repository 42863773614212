import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { delay, distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ContactFormComponent } from 'src/app/components/contact-form/contact-form.component';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { TasksComponent } from 'src/app/components/tasks/tasks.component';
import {
  ExtraLocationInformationDialogComponent,
  extraLocationInformationTypes
} from 'src/app/modules/trg-common/components/extra-location-information-dialog/extra-location-information-dialog.component';
import { AppConfigService } from 'src/app/providers/app-config.service';
// TODO: import applicationStateService in BaseComponent
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { DashboardService, DashboardView } from 'src/app/services/dashboard/dashboard.service';
import { QueryExtractPeerService } from 'src/app/services/query/query-extract-peer.service';
import { QueryService } from 'src/app/services/query/query.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { SiteSettingsService } from 'src/app/services/site-settings.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { WsService } from 'src/app/services/websocket/ws.service';
import {
  ExportPeerAction,
  ExtractPeerActionResult,
  ExtractPeerDialogComponent
} from 'src/app/shared/components/extract-peer-dialog/extract-peer-dialog.component';
import { NavbarIdentifier } from 'src/app/shared/models/navbar-identifier.enum';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import { NetworkStatus, Query, QueryStatus, SubscriptionStatus } from 'src/app/shared/models/query-item.model';
import { LanguageOptions, SiteSettings, SupportRequest, UserRole } from 'src/app/shared/models/site-settings.model';
import { Skins, Themes } from 'src/app/shared/models/skins.model';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Output() emitHeigth = new EventEmitter<number>();
  @ViewChild('targetHeight') targetElement: any;

  returnUrl: string;
  notificationCount = 0;
  connectivityIcon: string;
  connectedIcon = {
    true: 'assets/static/images/connected.svg',
    false: 'assets/static/images/disconnected.svg'
  };
  skin: string;
  sessionUser = this.localStorageService.getCurrentUser();
  user: User;
  isAdmin = false;
  isSupport = false;
  isPower = false;
  isMobileResolution: boolean;
  enabledApps: boolean;
  enableTactical: boolean;
  enabledLandingScreen: boolean;
  enableGeolocationQuery: boolean;
  enableUserManual: boolean;
  enableSupportRequest: boolean = true;
  theme: Themes;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  isIntelshieldTheme: Boolean = false;
  isGenershieldTheme: Boolean = false;
  searchIntelLoader: Boolean = false;
  flavor: string;
  showQueryBar = false;
  navBarIdentifier: NavbarIdentifier = NavbarIdentifier.MAINNAVBAR;
  fullNavbar: boolean = false;
  navbarBehaviour = true;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories
  };
  adintFlavourEnabled = false;
  enableKnowledgeBase = false;
  knowledgeBaseTooltips: { [key in Themes]: string } = {
    [Themes.GEOLOC]: 'Geoloc Knowledge Base',
    [Themes.WHITE]: 'Knowledge Base',
    [Themes.GEO]: 'Intellectus Knowledge Base',
    [Themes.FRESHVALE]: 'Intellectus Knowledge Base',
    [Themes.UNLIMITED]: 'Intellectus Knowledge Base',
    [Themes.INTELSHIELD]: '',
    [Themes.GENERSHIELD]: '',
  };
  knowledgeBaseTooltip: string;
  accurateLocation: boolean;
  showfloatingMsg: boolean;
  siteSettings: SiteSettings;
  availableExtractPeerActions = {
    getInfoAndLocate: ExportPeerAction.GET_INFO_AND_LOCATE,
    getInfo: ExportPeerAction.GET_INFO,
  };

  enableUserSettingsV2 = false;
  isLocatingDisabled = false;
  usernameDisplayValue: string;

  private readonly timeToWait = 5000;
  enableInvestigationModule: boolean;
  isCDROnlyEnabled = false;

  constructor(
    private authSessionService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private applicationStateService: ApplicationStateService,
    private dashboardService: DashboardService,
    private queryService: QueryService,
    private roleManagementService: RoleManagementService,
    private appConfigService: AppConfigService,
    private websocketService: WsService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private siteSettingsService: SiteSettingsService,
    private queryExtractPeerService: QueryExtractPeerService,
    private instantMessagesStore: InstantMessagesStore,
    public translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
    this.subscriptions.push(
      this.userService.getCurrentUser().subscribe((user: User) => {
        this.user = user;
        this.usernameDisplayValue = this.isSupport ? `${user.username} (${this.sessionUser.email})` : user.username;
      })
    );
    this.enabledApps = this.appConfigService.getConfigVariable('enabledApps');
    this.enableTactical = this.appConfigService.getConfigVariable('enableTactical');
    this.enabledLandingScreen = this.appConfigService.getConfigVariable('enabledLandingScreen');
    this.enableGeolocationQuery = this.appConfigService.getConfigVariable('enableGeolocationQuery');
    this.enableUserManual = this.appConfigService.getConfigVariable('enableUserManual');
    this.adintFlavourEnabled = this.appConfigService.getConfigVariable('enableAdintFlavour');
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.connectivityIcon = this.connectedIcon['false'];
    this.flavor = this.applicationStateService.getSkin();
    this.enableKnowledgeBase = this.appConfigService.getConfigVariable('enableKnowledgeBase');
    this.enableUserSettingsV2 = this.appConfigService.getConfigVariable('enableUserSettingsV2');
    this.isLocatingDisabled = this.appConfigService.getConfigVariable('disableLocatingFunctionality');
    // check when we change route
    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        startWith(this.router),
        distinctUntilChanged()
      )
      .subscribe(event => {
        this.navbarBehaviour = event.url === `/${ApplicationMainPageUrls.WEBINT}` ? false : true;

        this.showQueryBar =
          event.url === `/${ApplicationMainPageUrls.WEBINT}` ||
          event.url === '/admin' ||
          event.url === '/user-options' ||
          event.url === '/statistics'
            ? true
            : false;
      });


    // check on load
    this.showQueryBar = this.router.url === `/${ApplicationMainPageUrls.WEBINT}` ? true : false;
  }

  ngOnInit() {
    this.enableInvestigationModule = this.appConfigService.getConfigVariable('enableInvestigationModule');
    this.isCDROnlyEnabled = this.appConfigService.getConfigVariable('isCDROnlyEnabled');
    this.knowledgeBaseTooltip = this.knowledgeBaseTooltips[Themes.GEO];
    this.queryService.hideGeoSearch.subscribe(showSearch => {
      this.showQueryBar = showSearch;
    });

    if (this.theme === Themes.GEOLOC) {
      this.isGeolocTheme = true;
      this.knowledgeBaseTooltip = this.knowledgeBaseTooltips[Themes.GEOLOC];
    }

    if (this.theme === Themes.WHITE) {
      this.isWhiteTheme = true;
      this.knowledgeBaseTooltip = this.knowledgeBaseTooltips[Themes.WHITE];
    }

    if (this.theme === Themes.INTELSHIELD) {
      this.isIntelshieldTheme = true;
      this.knowledgeBaseTooltip =
        this.knowledgeBaseTooltips[Themes.INTELSHIELD];
    }

    if (this.theme === Themes.GENERSHIELD) {
      this.isGenershieldTheme = true;
      this.knowledgeBaseTooltip =
          this.knowledgeBaseTooltips[Themes.GENERSHIELD];
    }

    const skinSubscription = this.applicationStateService.skin.subscribe(
      (data: Skins) => {
        this.skin = data;
      }
    );

    const webSocketAdvancedGeoSubscription = this.websocketService
      .onEvent('state-update-geolocation')
      .subscribe((query: Query) => {
        if (query?.gps?.locationType && query?.createdBy === this.sessionUser.identity) {
          this.openExtraLocationInformationDialog(extraLocationInformationTypes.GPS, query);
        } else if (
          query &&
          query.nmr &&
          (query.nmr.trialterationLocation || query.nmr.sectorLocation) &&
          query.createdBy === this.sessionUser.identity &&
          query['tag'] !== '' &&
          !query['tag'] &&
          !this.accurateLocation
        ) {
          this.openExtraLocationInformationDialog(extraLocationInformationTypes.NMR, query);
        }

        const validStatusForActiveQuery = [SubscriptionStatus.ACTIVE, NetworkStatus.ONLINE, NetworkStatus.BUSY];
        if (
          query?.supportActiveQuery &&
          query.status === QueryStatus.DONE &&
          query?.cell?.lac &&
          !query?.cell?.cellId &&
          validStatusForActiveQuery.includes(NetworkStatus[query.device.networkStatus]) &&
          query.createdBy === this.sessionUser.identity
        ) {
          this.openExtraLocationInformationDialog(extraLocationInformationTypes.ACTIVE_LOCATE, query);
        }

        if (
          query?.queryArgs?.telno &&
          query.status !== QueryStatus.PENDING &&
          !query?.instantMessageProfiles
        ) {
          this.instantMessagesStore.fetchAllImPlatforms(query.queryArgs.telno, query.id);
        }
      });

    const linkAnalysisStateSubscription = this.applicationStateService.waitToSaveGraphOnLogout.subscribe(data => {
      if (data.proceed) {
        this.authSessionService.logout();
      }
    });

    const webSocketSubscriptionCallInfoQuery = this.websocketService
      .onEvent('state-update-advanced-call-info')
      .pipe(delay(2000))
      .subscribe((query: Query) => this.callInfoPopUp(query));

    this.subscriptions.push(
      skinSubscription,
      webSocketAdvancedGeoSubscription,
      linkAnalysisStateSubscription,
      webSocketSubscriptionCallInfoQuery
    );
    this.isAdmin = this.roleManagementService.userIsAdmin();
    this.isSupport = this.roleManagementService.userIsSupportUser();
    this.isPower = this.roleManagementService.userIsPowerUser();

    this.enableCurrentAccurateLocationSetting();

    this.subscriptions.push(
      this.siteSettingsService.getStoreSiteSettings()
      .pipe(distinctUntilChanged(isEqual))
      .subscribe((settings: SiteSettings) => {
        this.siteSettings = settings;
        settings.supportRequest.forEach((setting: SupportRequest) => {
          if (this.roleManagementService.userIsStandardUser() && setting.value === UserRole.USERS) {
            this.enableSupportRequest = setting.checked;
          }

          if (this.roleManagementService.userIsPowerUser() && setting.value === UserRole.POWER_USERS) {
            this.enableSupportRequest = setting.checked;
          }
        });
      })
    );
  }

  public changeLanguage(event: MatSelectChange): void {
    this.translationService.languageChange.next(<LanguageOptions>event.value);
    this.siteSettingsService.updateSiteLanguage(<LanguageOptions>event.value);
    this.cdRef.markForCheck();
  }

  private callInfoPopUp(query: Query): void {
    if (!!this.siteSettings.extractPeerInformation || !!this.siteSettings.extractPeerInformationAndLocate) {
      this.queryExtractPeerService.extractPeer({
        extractPeerInformationAndLocate: !!this.siteSettings.extractPeerInformationAndLocate,
        query,
      });
      return;
    }
    this.dialog
      .open(ExtractPeerDialogComponent, {
        data: {
          query,
          extractPeerInformationAndLocate: this.siteSettings.extractPeerInformationAndLocate || false,
          extractPeerInformation: this.siteSettings.extractPeerInformation || false
        },
        panelClass: 'extract-peer'
      })
      .afterClosed()
      .pipe(filter(actionResult => !!actionResult))
      .subscribe((actionResult: ExtractPeerActionResult) => {
        if (actionResult.action === ExportPeerAction.GET_INFO) {
          this.queryExtractPeerService.extractPeer({
            extractPeerInformationAndLocate: false,
            query,
          });
        }
        if (actionResult.action === ExportPeerAction.GET_INFO_AND_LOCATE) {
          this.queryExtractPeerService.extractPeer({
            extractPeerInformationAndLocate: true,
            query,
          });
        }
        if (!!actionResult.selection) {
          this.updateSiteSettings({
            extractPeerInformationAndLocate: actionResult.selection === ExportPeerAction.GET_INFO_AND_LOCATE,
            extractPeerInformation: actionResult.selection === ExportPeerAction.GET_INFO
          });
        }
      });
  }

  private updateSiteSettings(settings: Partial<SiteSettings>): void {
    this.siteSettingsService.updateSiteSettings(settings).subscribe();
  }

  ngAfterViewInit() {
    this.headerHeight();
  }

  changeExtractPeerAction(checkboxValue: boolean, action: ExportPeerAction): void {
    this.siteSettingsService.updateSiteSettings(<Partial<SiteSettings>>{
      [action]: checkboxValue,
      [action === ExportPeerAction.GET_INFO ? ExportPeerAction.GET_INFO_AND_LOCATE : ExportPeerAction.GET_INFO]: false
    })
    .subscribe();
  }

  enableCurrentAccurateLocationSetting() {
    this.subscriptions.push(
      this.userService
        .getCurrentAccurateLocation(this.roleManagementService.currentUser.identity)
        .subscribe((accurateLocation: boolean) => {
          this.accurateLocation = accurateLocation;
        })
    );
  }


  public changeAccurateLocation(flag: boolean): void {
    this.accurateLocation = flag;
    this.subscriptions.push(
      this.userService
        .changeAccurateLocation(flag, this.roleManagementService.currentUser.identity)
        .subscribe((accurateLocation: boolean) => {
          this.userService.handlingAccurateLocation.next(flag);
        })
    );
  }

  logout() {
    if (this.router.url.includes('link-analysis')) {
      this.applicationStateService.waitToSaveGraphOnLogout.next({ logout: true, proceed: false });
      return;
    }
    this.authSessionService.logout();
  }

  userManual() {
    let filePath = `/assets/static/files/user_manual_${this.appConfigService.getLanguage()}_GEO.pdf`;
    if (this.theme === Themes.GEOLOC) {
      filePath = '/assets/static/files/user_manual_es_GEOLOC.pdf';
    }
    window.open(filePath, '_blank');
  }

  public async openKnowledgeBaseTab() {
    const kbUsername = this.appConfigService.getConfigVariable('kbUsername');
    const kbPassword = this.appConfigService.getConfigVariable('kbPassword');
    const url = this.appConfigService.getConfigVariable('knowledgeBaseUrl');
    const newWindow = window.open(`https://${kbUsername}:${kbPassword}@${url}`, '_blank')
    setTimeout( () => {
      newWindow.location.href =  "https://intellectus.knewise.xyz";
    }, 700)
  }

  public redirectTo() {
    if ((this.enabledLandingScreen || this.flavor === Skins.GEO4) && this.router.url !== `/${ApplicationMainPageUrls.WEBINT}`) {
      this.dashboardService.componentsView.next(DashboardView.LOG);
      this.router.navigate([ApplicationMainPageUrls.WEBINT]);
    } else if (this.flavor === Skins.CVTP) {
      this.router.navigate([ApplicationMainPageUrls.CORE]);
    }
  }



  openSupportForm(): void {
    this.dialog.open(ContactFormComponent, {
      width: '30vw',
      height: 'auto',
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : ''
    });
  }

  openTasksDialog(flag): void {
    this.dialog.open(TasksComponent, {
      width: 'auto',
      height: '85vh',
      data: flag, // canceled task = true OR false
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : ''
    });
  }

  loaderShow(loadVal) {
    this.searchIntelLoader = loadVal;
  }

  public getLogoText(): string {
    if (this.flavor === Skins.CVTP) {
      return 'CVTP';
    } else if (this.theme === Themes.FRESHVALE) {
      return 'Freshvale';
    }
  }

  public getLogo(): string {
    if (this.flavor === Skins.CVTP) {
      return 'assets/static/images/cvd-logo.svg';
    } else if (this.theme === Themes.FRESHVALE) {
      return 'assets/static/images/freshvale-favicon-logo.png';
    } else if (this.theme === Themes.INTELSHIELD) {
      return 'assets/static/images/intelshield-logo.png';
    } else if (this.theme === Themes.GENERSHIELD) {
      return 'assets/static/images/genershield-logo.png';
    }
    return 'assets/static/images/intellectus-logo.png';
  }

  public navigateToLinkAnalysis() {
    this.router.navigate([`${ApplicationMainPageUrls.WEBINT}/link-analysis`]);
  }

  headerHeight() {
    setTimeout(() => {
      const headerHeight = this.targetElement.nativeElement.offsetHeight;
      this.emitHeigth.emit(headerHeight);
    }, 100);
  }

  public openChangePasswordDialog() {
    this.dialog.open(ResetPasswordComponent, {
      width: this.isMobileResolution ? '100vw' : '25vw',
      height: 'auto',
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : ''
    });
  }

  private openExtraLocationInformationDialog(type: extraLocationInformationTypes, query: Query) {
    this.dialog
      .open(ExtraLocationInformationDialogComponent, {
        width: '30vw',
        height: 'auto',
        disableClose: true,
        data: { type, query },
        panelClass: 'extra-location-information-dialog'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (!result) return;

        this.showfloatingMsg = result;
        setTimeout(() => {
          this.showfloatingMsg = false;
        }, this.timeToWait);
      });
  }

}
