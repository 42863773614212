import { EventEmitter, Injectable } from '@angular/core';
import { Avatar } from 'datalayer/models/avatar';
import { AvatarActivityLog } from 'datalayer/models/avatar/avatar-activity-log';
import { AvatarActivityTask } from 'datalayer/models/avatar/avatar-activity-task';
import { AvatarPerson } from 'datalayer/models/avatar/avatar-person';
import { AvatarRemoteView } from 'datalayer/models/avatar/avatar-remote-view';
import { AvatarRemoteViewStatus } from 'datalayer/models/avatar/avatar-remote-view-status.enum';
import { AvatarSocialType } from 'datalayer/models/avatar/avatar-social-type.enum';
import { BaseService, DataChangeNotification, EmptyCacheService, RequestOptions } from 'datalayer/services/base';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AvatarActivity } from 'src/app/modules/avatar/models/avatar-activities.class';
import { AvatarCreateInteraction } from 'src/app/modules/avatar/models/avatar-create-interaction.interface';
import { AvatarApiService } from './avatar-api.service';
import { AvatarDataChangeType } from './avatar-data-change-motification';
import { AvatarDTO } from './avatar-dto';

@Injectable({
  providedIn: 'root'
})
export class AvatarService extends BaseService<
  Avatar,
  AvatarDTO,
  AvatarApiService,
  EmptyCacheService<Avatar>,
  AvatarDataChangeType
> {
  // INT-11646 => this event emitter shouldn't exist. we have methods of updating models through super.onResourceChanged();
  private onAddedOrUpdatedAvatar: EventEmitter<{avatar: Avatar, type: AvatarDataChangeType}> = new EventEmitter<{avatar: Avatar, type: AvatarDataChangeType}>();
  public onAddedOrUpdatedAvatar$ = this.onAddedOrUpdatedAvatar.asObservable();
  
  constructor(private apiService: AvatarApiService, emptyCacheService: EmptyCacheService<Avatar>) {
    super(apiService, emptyCacheService);
  }

  public onAvatarCreated(avatar): Observable<Avatar> {
    return this.apiService.create(avatar).pipe(
      take(1),
      tap((response) => {
        this.onAddedOrUpdatedAvatar.emit({avatar: response, type: AvatarDataChangeType.Create})
        return response;
      })
    );
  }

  public onAvatarUpdated(avatar): Observable<Avatar> {
    return this.apiService.bindToUser(avatar).pipe(
      take(1),
      tap(() => this.onAddedOrUpdatedAvatar.emit({avatar: avatar, type: AvatarDataChangeType.Update})),
      map(() => avatar)
    );
  }

  public getAvatarActivityTasks(): Observable<AvatarActivityTask[]> {
    return this.apiService.getAvatarActivityTasks();
  }

  public getActivityLog(avatarId: string): Observable<AvatarActivityLog[]> {
    return this.apiService.getAvatarActivityLog(avatarId);
  }

  public onStopAction(activity: AvatarActivity): Observable<AvatarActivityTask> {
    return this.apiService.onStopAction(activity);

  }
  public onRunAction(activity: AvatarActivity): Observable<AvatarActivityTask> {
    return this.apiService.onRunAction(activity);
  }
  public availableAvatars(options: RequestOptions): Observable<Avatar[]> {
    return this.apiService.availableAvatars(options);
  }

  public generateCredentials(options: RequestOptions): Observable<{ name: string; lastName: string }> {
    return this.apiService.generateCredentials(options);
  }

  public getPersons(options?: RequestOptions): Observable<AvatarPerson[]> {
    return this.apiService.getPersons(options);
  }

  public reserveRemoteView(
    avatarId: string,
    socialPlatform: AvatarSocialType,
    options?: RequestOptions
  ): Observable<AvatarRemoteView> {
    return this.apiService.reserveRemoteView(avatarId, socialPlatform, options);
  }

  public checkRemoteViewStatus(
    avatarId: string,
    socialPlatform: AvatarSocialType
  ): Observable<AvatarRemoteView> {
    return this.apiService.checkRemoteViewStatus(avatarId, socialPlatform);
  }

  public releaseRemoteView(avatarId: string, socialPlatform: AvatarSocialType): Observable<AvatarRemoteViewStatus> {
    return this.apiService.releaseRemoteView(avatarId, socialPlatform);
  }

  protected onResourceChanged(res: DataChangeNotification<Avatar, AvatarDataChangeType>): void {
    switch (res.type) {
      case AvatarDataChangeType.RemoteViewEnded: {
        this.externalDataChangeNotification.next({ type: AvatarDataChangeType.RemoteViewEnded, models: res.models });
        super.onResourceChanged({ type: AvatarDataChangeType.Update, models: res.models });
        break;
      }
      case AvatarDataChangeType.RemoteViewStarted: {
        this.externalDataChangeNotification.next({ type: AvatarDataChangeType.RemoteViewStarted, models: res.models });
        super.onResourceChanged({ type: AvatarDataChangeType.Update, models: res.models });
        break;
      }
      default: {
        super.onResourceChanged(res);
        break;
      }
    }
  }
  public createInteraction(model: AvatarCreateInteraction): Observable<AvatarActivityTask> {
    return this.apiService.createInteraction(model);
  }
  public renew(avatar: Avatar): Observable<boolean> {
    return this.apiService.renew(avatar).pipe(
      take(1),
      tap(() => this.onAddedOrUpdatedAvatar.emit({avatar: avatar, type: AvatarDataChangeType.Update}))
    );
  }
  public decode(dto: AvatarDTO): Avatar {
    return this.apiService.decode(dto);
  }
}
