import { Injectable } from '@angular/core';
import { Profile } from 'datalayer/models/social/profile';
import { BaseService, EmptyCacheService, ModelStore, RequestOptions } from 'datalayer/services/base';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileApiService } from 'src/app/modules/data-layer/services/social/profile/profile-api-service';
import { ProfileDTO } from 'src/app/modules/data-layer/services/social/profile/profile-dto';
import { ActiveModule, FormatDateOfBirth, TypeOfDob } from 'src/app/shared/models/date-of-birth.enum';
import { map } from 'rxjs/operators';
import { DataSource } from 'datalayer/models/platform-models';
import { cloneDeep, uniq } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService<Profile, ProfileDTO, ProfileApiService, EmptyCacheService<Profile>> {
  aboutData = new BehaviorSubject({});
  readonly regexDobNoYear = /^\d+\/\d+$/g;
  readonly regexDobOnlyYear = /^\d{4}$/g;

  private plainAboutData = {
    names: [],
    usernames: [],
    dateOfBirth: null,
    gender: null,
    addresses: [],
    maritalStatus: null,
    about: [],
  };

  constructor(apiService: ProfileApiService, localCacheService: EmptyCacheService<Profile>) {
    super(apiService, localCacheService);
  }

  public getAll(options?: RequestOptions): Observable<ModelStore<Profile>> {
    return super.getAll(options).pipe(map((profiles) => this.mergeTruecallerWithCallerIDProfiles(profiles)));
  }

  private mergeTruecallerWithCallerIDProfiles(profiles: ModelStore<Profile>): ModelStore<Profile> {
    const mergedProfiles: ModelStore<Profile> = cloneDeep(profiles);
    const profilesValue = Object.values(mergedProfiles);
    const truecallerProfiles: Profile[] = profilesValue.filter((profile) => DataSource.Truecaller === profile.source);
    const calleridProfiles: Profile[] = profilesValue.filter((profile) => DataSource.CallerID === profile.source);
    const calleridProfilesExists: boolean = !!calleridProfiles.length;
    const truecallerProfilesExists: boolean = !!truecallerProfiles.length;

    if (truecallerProfilesExists && !calleridProfilesExists) {
      truecallerProfiles.forEach((profile) => {
        profile.source = DataSource.CallerID;
        mergedProfiles[profile.id] = profile;
      });
    }

    if (truecallerProfilesExists && calleridProfilesExists) {
      calleridProfiles.forEach((calleridProfile: Profile) =>
        this.replaceMergedProfileOnBaseProfilesMap(truecallerProfiles, calleridProfile, mergedProfiles)
      );
    }
    return mergedProfiles;
  }

  private replaceMergedProfileOnBaseProfilesMap(
    truecallerProfiles,
    calleridProfile: Profile,
    baseProfileMap: ModelStore<Profile>
  ): void {
    this.mergeProfilesBySameSource(truecallerProfiles, calleridProfile, baseProfileMap);
    delete baseProfileMap[calleridProfile.id];
  }

  private mergeProfilesBySameSource(
    targetProfiles: Profile[],
    sourceProfile: Profile,
    baseProfileMap: ModelStore<Profile>
  ) {
    const calleridProfileQueryArg = this.getProfileSourceQueryArg(sourceProfile);
    targetProfiles.forEach((targetProfile: Profile) => {
      if (targetProfile.sourceEntity.id.includes(calleridProfileQueryArg) || targetProfile.telno === sourceProfile.telno) {
        const newProfile: Profile = this.createNewCallerIdProfile(targetProfile, sourceProfile);
        baseProfileMap[newProfile.id] = newProfile;
      }
    });
  }

  private getProfileSourceQueryArg(profile: Profile): string {
    return profile.sourceEntity.id.split('@')[0];
  }

  private createNewCallerIdProfile(profileA: Profile, profileB: Profile): Profile {
    const availableUrl = uniq([profileA.url, profileB.url]).filter(Boolean);
    const newProfile: Profile = {
      ...profileA,
      name: uniq([profileA.name.trim(), profileB.name.trim()]).filter(Boolean).join(', '),
      telno: uniq([profileA.telno, profileB.telno]).filter(Boolean).join(', '),
      location: uniq([profileA.location, profileA?.nameLocation?.title, profileB.location, profileB?.nameLocation?.title,]).filter(Boolean).join(', '),
      url: availableUrl.length ? availableUrl[0] : '',
      source: DataSource.CallerID,
    };
    return newProfile;
  }

  clearAboutData(): void {
    this.plainAboutData = {
      names: [],
      usernames: [],
      dateOfBirth: null,
      gender: null,
      addresses: [],
      maritalStatus: null,
      about: [],
    };

    this.aboutData.next(this.plainAboutData);
  }

  setAboutData(profiles: Profile[]): void {
    if (!Array.isArray(profiles)) {
      return;
    }

    profiles.forEach((profile) => {
      if (!profile) {
        return;
      }

      const { name, gender, username, dob, currentCity, nameLocation, location, relationshipStatus, about } = profile;
      const address = currentCity?.title || nameLocation?.title || location;

      if (name && !this.plainAboutData.names.includes(name)) {
        this.plainAboutData.names.push(name);
      }

      if (relationshipStatus && !this.plainAboutData.maritalStatus) {
        this.plainAboutData.maritalStatus = relationshipStatus;
      }

      if (username && !this.plainAboutData.usernames.includes(username)) {
        this.plainAboutData.usernames.push(username);
      }

      if (about && !this.plainAboutData.about.includes(about)) {
        this.plainAboutData.about.push(about);
      }

      if (gender && !this.plainAboutData.gender) {
        this.plainAboutData.gender = gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
      }

      if (dob && !this.plainAboutData.dateOfBirth) {
        const dateFormat = this.formatDateOfBirth(dob, ActiveModule.ABOUT);
        if (dateFormat.dateOfBirth) {
          this.plainAboutData.dateOfBirth = new Date(dateFormat.dateOfBirth);
        }
      }

      if (address && !this.plainAboutData.addresses.includes(address)) {
        this.plainAboutData.addresses.push(address);
      }
    });

    this.aboutData.next(this.plainAboutData);
  }

  gettingRelationsComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  formatDateOfBirth(birthday: string, module?: string): FormatDateOfBirth {
    let dateOfBirth: string = birthday;
    let typeOfBirthday: TypeOfDob;

    if (birthday.match(this.regexDobNoYear)) {
      dateOfBirth = module && module === ActiveModule.ABOUT ? undefined : birthday;
      typeOfBirthday = TypeOfDob.NO_YEAR;
    } else if (birthday.match(this.regexDobOnlyYear)) {
      dateOfBirth = module && module === ActiveModule.ABOUT ? undefined : birthday;
      typeOfBirthday = TypeOfDob.ONLY_YEAR;
    }

    return { dateOfBirth, typeOfBirthday };
  }
}
