import { BaseService, EmptyCacheService } from 'datalayer/services/base';
import { Media } from 'datalayer/models/media/media';
import { MediaApiService } from 'src/app/modules/data-layer/services/media/media/media-api-service';
import { Injectable } from '@angular/core';
import { MediaDTO } from 'src/app/modules/data-layer/services/media/media/media-dto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService extends BaseService<Media, MediaDTO, MediaApiService, EmptyCacheService<Media>> {
  constructor(apiService: MediaApiService, localCacheService: EmptyCacheService<Media>) {
    super(apiService, localCacheService);
  }

  gettingMediaComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
