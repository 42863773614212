<div
  [class.mxnl]="enabledMxnl"
  [class.login-mobile]="isMobileResolution"
  [class.geoloc-login]="isGeolocTheme"
  [class.freshvale-login]="isFreshvaleTheme"
  [class.intelshield-login]="isIntelShieldTheme"
  [class.genershield-login]="isGenerShieldTheme"
  class="login"
  [class.geo-login]="!cvtpFlavor"
  [class.mxnl-mobile]="enabledMxnl && isMobileResolution"
>
  <div class="center-xs row logo-wrp" [class.mxnl-form]="enabledMxnl && !isMobileResolution">
    <img
      *ngIf="!isWhiteTheme && !enabledMxnl"
      class="logo"
      [src]="getLogo()"
      [style.width.px]="this.cvtpFlavor ? 150 : 200"
    />
    <p class="logo-txt" *ngIf="cvtpFlavor">CVTP</p>
    <div class="logo" *ngIf="isWhiteTheme || enabledMxnl"></div>
  </div>
  <!-- Login Form -->
  <form [formGroup]="loginForm" class="login-form assistant-font" *ngIf="formState === formStates.LOGIN">
    <div class="row form-wrp">
      <mat-card [class.login-card]="!cvtpFlavor" [class.cvtp-login-card]="cvtpFlavor">
        <mat-card-content [@openCloseLoginPanel]="!showLoader ? 'open' : 'closed'">
          <div>
            <div class="user-input field-group">
              <label class="assistant-font" *ngIf="!cvtpFlavor">{{ 'Username' | translate }}</label>
              <label class="assistant-font" *ngIf="cvtpFlavor">{{ 'Username' | translate }}</label>
              <mat-form-field [class.mob-input]="isMobileResolution" class="input-round">
                <input class="login-field assistant-font" formControlName="username" matInput required />
              </mat-form-field>
              <mat-error class="errorMsg input-error" *ngIf="userError">{{
                'Please Enter Username' | translate
              }}</mat-error>
            </div>

            <div class="field-group">
              <label class="assistant-font">{{ 'Password' | translate }}</label>
              <mat-form-field [class.mob-input]="isMobileResolution" class="input-round">
                <input
                  class="login-field assistant-font"
                  formControlName="password"
                  #password
                  *ngIf="loginState === 'ENTERING_PASSWORD'"
                  [type]="visibility ? 'text' : 'password'"
                  matInput
                />

                <mat-icon
                  *ngIf="loginState === 'ENTERING_PASSWORD'"
                  class="icon eye-icon"
                  (click)="this.visibility = !this.visibility"
                >
                  {{ this.visibility === true ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>
            </div>
            <mat-error class="errorMsg" *ngIf="errorMsg">{{ errorMsg | translate }}</mat-error>
          </div>
        </mat-card-content>
        <span *ngIf="showLoader" class="loading-text">Authenticating to system</span>
        <div class="login-btn">
          <button class="cp-btn-primary login-action" [class.loading]="showLoader" mat-button (click)="onSubmit()">
            {{ 'LOG IN' | translate }}
          </button>
        </div>
      </mat-card>
    </div>
  </form>

  <!-- Change Password Form -->
  <div class="change-password-wrp" *ngIf="formState === formStates.CHANGE_PASSWORD">
    <ng-container *ngTemplateOutlet="changeUser"></ng-container>
    <app-change-password
      [currentPassword]="loginForm.controls['password'].value"
      (passwordFormValid)="onPasswordFormValidationChange($event)"
      [isPartOfOtpForm]="true"
    ></app-change-password>

    <!-- 2FA -->
    <ng-container
      *ngTemplateOutlet="
        verification.is2faSelection ? selectOtpMethod : verification.is2faRequired ? requestOtpInput : emptyTemplate;
        context: {
          is2faEnforced: verification.is2faEnforced,
          enableEdit: !currentUser?.otpEnabled,
          showQrCode: !currentUser?.otpEnabled,
          channel: verification.channel,
          recipient: verification.recipient
        }
      "
    ></ng-container>

    <mat-error class="errorMsg" *ngIf="errorMsg">{{ errorMsg | translate }}</mat-error>

    <div class="login-btn">
      <button
        class="cp-btn-primary login-action otp-action"
        mat-button
        [disabled]="
          showLoader ||
          (verification.isPasswordResetRequired && !passwordFormValue.valid) ||
          (verification.is2faSelectionRadioVisible &&
            ((emailFormValue.value && !emailFormValue.valid) ||
              (phoneFormValue.value && !phoneFormValue.valid) ||
              !verification.channel)) ||
          (verification.is2faRequired && !otpFormValue.valid)
        "
        (click)="
          (verification.is2faSelection && verification.is2faSelectionRadioVisible)
            ? onSelectOtpMethodApply()
            : verification.is2faRequired
            ? onSubmitOtpCode({ resetPassword: true })
            : resetUserPassword()
        "
      >
        {{ 'Apply' | translate }}
      </button>
    </div>
  </div>

  <!-- Configure Two Step Verification -->
  <div class="verification-wrp login-block" *ngIf="formState === formStates.CONFIGURE_VERIFICATION">
    <ng-container
      *ngTemplateOutlet="
        selectOtpMethod;
        context: { hasTitle: true, is2faEnforced: verification.is2faEnforced, enableEdit: true }
      "
    ></ng-container>

    <div class="login-btn">
      <button
        class="cp-btn-primary login-action otp-action"
        mat-button
        [disabled]="
          showLoader ||
          (emailFormValue.value && !emailFormValue.valid) ||
          (phoneFormValue.value && !phoneFormValue.valid) ||
          !verification.channel
        "
        (click)="onSelectOtpMethodApply()"
      >
        {{ 'Apply' | translate }}
      </button>
    </div>
  </div>

  <!-- Two Step Verification -->
  <div class="otp-active-verify login-block" *ngIf="formState === formStates.VERIFICATION">
    <ng-container
      *ngTemplateOutlet="
        verification.isResetting2Fa ? reset2FA : requestOtpInput;
        context: {
          hasTitle: true,
          showQrCode: false,
          canChangeUser: true,
          channel: verification.channel,
          recipient: verification.isResetting2Fa ? verification.reset2faRecipient : verification.recipient
        }
      "
    ></ng-container>
    <div class="login-btn" [class.flex-wrp]="verification.is2faResetEnabled">
      <button
        *ngIf="verification.is2faResetEnabled"
        class="cp-btn-primary login-action otp-action btn-stroke"
        type="button"
        mat-button
        [disabled]="showLoader"
        (click)="onReset2FAVerification()"
      >
        {{ 'Reset' | translate }}
      </button>
      <button
        class="cp-btn-primary login-action otp-action"
        mat-button
        [disabled]="showLoader || !otpFormValue.valid"
        (click)="verification.is2faEnforced ? onSubmitOtpCode() : onSubmit()"
      >
        {{ 'Apply' | translate }}
      </button>
    </div>
  </div>

  <footer *ngIf="enabledMxnl">
    <div class="row center-xs">
      <div class="col-11 col-md-7 footer">
        {{
          'GiO - GeoLocation Services - For exclusive usage by government law enforcement agency: Government of the State of Nuevo Leon - Backed up by EUC - 2017'
            | translate
        }}
      </div>
    </div>
  </footer>
</div>

<!-- 2FA selection template -->
<ng-template
  #selectOtpMethod
  let-hasTitle="hasTitle"
  let-is2faEnforced="is2faEnforced"
  let-enableEdit="enableEdit"
>
  <p class="two-step-auth" [class.title]="hasTitle" [class.activate]="!hasTitle">
    {{ (is2faEnforced ? '2-Step Verification is OFF' : 'Activate 2-step verification') | translate }}
  </p>

  <p class="two-step-auth description">
    {{ "A second step after entering your password verifies it's you signing in." | translate }}
  </p>

  <app-otp-selection-radio
    (channelChange)="onChannelChanged($event)"
    (phoneChange)="onPhoneChanged($event)"
    (emailChange)="onEmailChanged($event)"
    (visibilityChange)="onRadioVisibilityChange($event)"
    [mandatoryEnablement]="is2faEnforced"
    [enableEdit]="enableEdit"
  ></app-otp-selection-radio>
</ng-template>

<!-- 2fa input template -->
<ng-template
  #requestOtpInput
  let-hasTitle="hasTitle"
  let-channel="channel"
  let-recipient="recipient"
  let-showQrCode="showQrCode"
  let-canChangeUser="canChangeUser"
>
  <p *ngIf="hasTitle" class="two-step-auth title">{{ '2-Step Verification' | translate }}</p>

  <ng-container *ngTemplateOutlet="canChangeUser ? changeUser : emptyTemplate"></ng-container>

  <p *ngIf="channel === otpChannels.App" class="two-step-auth description">
    {{ "A second step after entering your password verifies it's you signing in." | translate }}
    {{ showQrCode ? ('For generating OTP codes, please download the Authenticator App' | translate) : '' }}
    {{ showQrCode ? ':' : '' }}
  </p>
  <!-- Legacy channels -->
  <app-activate-otp-code-input
    *ngIf="channel === otpChannels.Email || channel === otpChannels.OtpPhone"
    [otpChannel]="channel"
    [otpRecipient]="recipient"
    [errorMsg]="errorMsg"
    (otpControlChanged)="onOTPCodeChanged($event)"
  ></app-activate-otp-code-input>

  <!-- App channel -->
  <app-activate-qr-otp-code-input
    *ngIf="channel === otpChannels.App"
    [errorMsg]="errorMsg"
    [showOtpImage]="showQrCode"
    (otpControlChanged)="onOTPCodeChanged($event)"
  ></app-activate-qr-otp-code-input>
</ng-template>

<!-- 2fa reset template -->
<ng-template #reset2FA let-recipient="recipient">
  <div class="reset-2fa">
    <div class="email-content" *ngIf="!showLoader">
      <p class="sent">{{ 'Email sent.' | translate }}</p>
      <p class="input-desc">
        {{ 'An email with the QR was sent to' | translate }} <span>{{ recipient }}</span>
      </p>
    </div>
    <button class="cp-btn-primary" mat-button (click)="onBackToLogin()">
      {{ 'Back to login' | translate }}
    </button>
  </div>
</ng-template>

<!-- change user template -->
<ng-template #changeUser>
  <div class="user-change-wrp">
    {{ 'User' | translate }}: {{ loginForm.controls['username'].value }}
    <span class="change-user" (click)="onUserChangedClicked()">{{ 'Change' | translate }}</span>
  </div>
</ng-template>

<ng-template #emptyTemplate></ng-template>
