import { Injectable } from '@angular/core';
import { Node as KeyLinesNode, Link } from '@trg-ui/link-analysis';
import { LinkAnalysisService } from '../../link-analysis/services/link-analysis.service';
import { Subject } from 'rxjs';
import { FileTextAnalysisService } from 'src/app/modules/file-text-analysis/services/file-text-analysis.service';
import {
  EventNodeData,
  FileNodeData,
  LabelSize,
  nodeSize,
  nodeTypes,
  nodeTypesColors,
  relationTypes,
} from '../../link-analysis/shared/link-analysis.model';
import {
  GraphServiceResponse,
  RawEdge,
  RawEventEntityNode,
  RawNode,
} from '../models/graph-entities.model';
import { BaseService } from 'src/app/services/base.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Entity, Event } from '@trg-ui/timeline-visualization';
import { extractedEntitiesAllowedTypes } from '../../file-text-analysis/models/file-analysis.model';

@Injectable({
  providedIn: 'root',
})
export class GraphEntitiesService extends BaseService {
  graphReadyData = new Subject<{ [key: string]: KeyLinesNode | Link }>();
  rawData = new Subject<GraphServiceResponse>();
  eventsLocations = new Subject<RawNode[]>();
  constructor(
    private linkAnalysisService: LinkAnalysisService,
    private fileAnalysisService: FileTextAnalysisService,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {
    super(router, snackBar);
  }

  public transformDataToNodesAndLinks(input: GraphServiceResponse) {
    const nodesOut = this.transformNodes(input.nodes);
    const edgesOut = this.transformEdges(input.edges);
    return { ...nodesOut, ...edgesOut };
  }

  public transformNodes(input: RawNode[]): { [key: string]: KeyLinesNode } {
    const output: { [key: string]: KeyLinesNode } = {};
    input.forEach((node) => {
      let nodeType = this.linkAnalysisService.parseTextToNodeType(node.type);
      let nodeLabel = this.getNodeTextByType(node, nodeType);
      if (nodeType === nodeTypes.NAMED_ENTITY) {
        nodeType = this.linkAnalysisService.parseTextToNodeType(node.label);
      }
      const nodeData = this.getNodeDataByType(nodeType, node);
      if (!extractedEntitiesAllowedTypes.includes(nodeType)) {
        return;
      }
      const platform: relationTypes = this.linkAnalysisService.parsePlatform(node.source);
      const glyphs = platform ? [this.linkAnalysisService.getGlyph(`link-analysis/${platform}.svg`, 'e')] : [];
      let newNode = this.linkAnalysisService.createNewNode(
        node.id,
        nodeTypesColors.FILE,
        nodeLabel,
        nodeData,
        nodeSize.SMALL,
        glyphs,
        LabelSize.MEDIUM
      );
      output[node.id] = newNode as unknown as KeyLinesNode;
    });
    return output;
  }

  public transformEdges(input: RawEdge[]): { [key: string]: Link } {
    const output: { [key: string]: Link } = {};
    input.forEach((edge) => {
      let newEdge = this.linkAnalysisService.getLink(
        edge.fromEntity.id,
        edge.toEntity.id,
        this.linkAnalysisService.parseRelation(edge.relationType),
        false,
        nodeTypesColors.FILE
      );
      const metadata = edge.metadata;
      newEdge.d = { ...newEdge.d, metadata };
      output[newEdge.id] = newEdge as unknown as Link;
    });
    return output;
  }

  private getNodeTextByType(node: RawEventEntityNode, nodeType: nodeTypes): string {
    switch (nodeType) {
      case nodeTypes.FILE:
        return node.fileName;
      case nodeTypes.NAMED_ENTITY:
        return node.text;
      case nodeTypes.EVENT_ENTITY:
        return node.action
      case nodeTypes.LOCATION:
        return node.name;
      default:
        return '';
    }
  }

  private getNodeDataByType(nodeType: nodeTypes, node: RawNode) {
    let nodeData: EventNodeData |  FileNodeData  = {
      type: nodeType,
      label: this.getNodeTextByType(node, nodeType),
    };
    switch (nodeType) {
      case nodeTypes.FILE:
        nodeData.uploadedAt = node.createdAt;
        break;
      case nodeTypes.EVENT_ENTITY:
        nodeData = { ...nodeData, ...node } as unknown as EventNodeData;
        break;
    }
    return nodeData;
  }

  public fetchGraphData(fileId: string) {
    this.fileAnalysisService.fetchFileAnalysis(fileId).subscribe({
      next: (data: GraphServiceResponse) => {
        let httpError = data as unknown as HttpErrorResponse;
        if (httpError.ok === false) {
          this.showMessage(
            this.translationService.translate(
              'An error occurred while trying to extract entities'
            )
          );
        } else {
          this.rawData.next(data);
          const eventNodes = data.nodes.filter(this.isExtractedEventRawNode);
          this.eventsLocations.next(eventNodes);
          this.graphReadyData.next(this.transformDataToNodesAndLinks(data));
        }
      },
      error: () => {
        this.showMessage(
          this.translationService.translate(
            'An error occurred while trying to extract entities'
          )
        );
      },
    });
  }

  public getTimelineReadyData(input: GraphServiceResponse): {
    entities: { [key: string]: Entity };
    events: { [key: string]: Event };
  } {
    if (!input.nodes.length) {
      return;
    }
    const timelineData: {
      entities: { [key: string]: Entity };
      events: { [key: string]: Event };
    } = {
      events: {},
      entities: {},
    };

    const timelineEntity: Entity = {
      color: nodeTypesColors.FILE,
      data: input.nodes[0]?.metadata,
      label: input.nodes[0].fileName,
      glyph: true,
      type: input.nodes[0].type,
    };

    timelineData.entities[input.nodes[0].id] = timelineEntity;

    Object.values(input.nodes)
      .filter((elem) => elem.label === 'Date')
      .map((elem) => ({ ...elem, text: new Date(elem.text) }))
      .filter((elem) => elem.text.toString() !== 'Invalid Date')
      .forEach((elem) => {
        const timeLineElement: Event = {
          color: nodeTypesColors.FILE,
          time: elem.text,
          entityIds: [input.nodes[0].id],
          data: elem,
          type: elem.type,
        };
        timelineData.events[elem.id] = timeLineElement;
      });
    return timelineData;
  }

  private isExtractedEventRawNode(node: RawNode): boolean {
    return Array.isArray(node.category);
  }
}
