import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Themes } from 'src/app/shared/models/skins.model';
import { NavbarMenuItem } from './nav-bar.model';
import { QueryService } from 'src/app/services/query/query.service';
import { DashboardService, DashboardView } from 'src/app/services/dashboard/dashboard.service';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { WebintDisabledService } from 'src/app/shared/components/webint-disabled-modal/webint-disabled.service';
import { NavMenuSubItemComponent } from './nav-menu-sub-item/nav-menu-sub-item.component';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss'],
})
export class NavbarMenuComponent implements OnInit {
  @ViewChildren('submenu') subMenus: QueryList<NavMenuSubItemComponent>;
  constructor(
    private appConfigService: AppConfigService,
    private queryService: QueryService,
    private dashboardService: DashboardService,
    private router: Router,
    private webintDisabledService: WebintDisabledService) {
      this.isLocatingDisabled = this.appConfigService.getConfigVariable('disableLocatingFunctionality');
  }
  actualTheme: Themes;
  themes = Themes;
  activeMenuItems: NavbarMenuItem[] = [];
  isLocatingDisabled = false;

  ngOnInit(): void {
    this.actualTheme = this.appConfigService.getConfigVariable('theme');
    this.buildMenu();
    this.checkActiveRoute();
  }

  checkActiveRoute(): void {
    this.router.events.pipe(
      filter((event: NavigationEnd) => event instanceof NavigationEnd),
      startWith(this.router),
      distinctUntilChanged()).subscribe(event => {
      const isActive = this.isTargetsOrCasesOrInvestigationRoute(event.url);
      this.activeMenuItems.map((item) => item.routeActive = false)
      if(isActive){
       this.activeMenuItems.find((item) => item.name === 'Core').routeActive = true
       return;
      }
      if(event.url === `/${ApplicationMainPageUrls.WEBINT}/history`) {
        this.activeMenuItems.find((item) => item.name === 'Webint').routeActive = true;
        return;
      }
    });
  }

  buildMenu(): void {
    const menus: NavbarMenuItem[] = [
      {
        name: 'Core',
        svgIcon: 'analysis-menu',
        angulartics2On: '',
        link: null,
        angularticsAction: '',
        angularticsCategory: '',
        visible: true,
        children: [
          {
            name: 'Targets',
            link: `/${ApplicationMainPageUrls.CORE}/targets`,
            svgIcon: 'target-menu',
            description: 'List of all targets',
            visible: true,
          },
          {
            name: 'Cases',
            link: `/${ApplicationMainPageUrls.CORE}/cases`,
            svgIcon: 'cases-menu',
            description: 'List of all cases',
            visible: true,
          },
          {
            name: 'Investigation',
            link: `/${ApplicationMainPageUrls.CORE}/investigations`,
            svgIcon: 'investigation-menu',
            description: 'Tool to analyze different types of intelligence',
            visible: this.appConfigService.getConfigVariable('enableInvestigationModule'),
            betaSign: true
          },
        ],
      },
      {
        name: 'Discovery',
        svgIcon: 'geolocation',
        link: '/discovery',
        angulartics2On: 'click',
        angularticsAction: 'matomo.actions.discovery',
        angularticsCategory: 'matomo.categories.navBar',
        visible: !this.isLocatingDisabled,
        onClick: () => this.onNavigateToQuery()
      },
      {
        name: 'Webint',
        svgIcon: 'webint-menu',
        link: null,
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible: true,
        onClick: () => this.onWebint()
      },
      {
        name: 'Call Logs',
        svgIcon: 'call-logs-menu',
        link: `/${ApplicationMainPageUrls.CORE}/call-logs`,
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible: true,
      },

      {
        name: 'Virtual ID',
        svgIcon: 'avatars',
        link: '/virtual-id',
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        betaSign: true,
        visible: this.appConfigService.getConfigVariable('enableAvatars'),
      },
      {
        name: 'Adint',
        svgIcon: 'adint-map',
        link: '/adint',
        angulartics2On: 'click',
        angularticsAction: 'matomo.actions.adint',
        angularticsCategory: 'matomo.categories.navBar',
        betaSign: true,
        visible: this.appConfigService.getConfigVariable('enableAdInt'),
      },
      {
        name: 'Tactical',
        link: '/tactical',
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible: this.appConfigService.getConfigVariable('enableTactical'),
        onClick: () => this.onNavigateToTactical()
      },
      {
        name: 'Darkweb',
        link: '/darkweb',
        angulartics2On: '',
        angularticsAction: '',
        angularticsCategory: '',
        visible: this.appConfigService.getConfigVariable('enableDarkwebLive'),
      },
    ];
    this.activeMenuItems = menus.filter((item) => item.visible);
  }


  handleClick(item: NavbarMenuItem): void {
    if(item.onClick) {
      item.onClick();
    }
  }

  onWebint(): void {
    const disableWebintActions = this.appConfigService.getConfigVariable('disableWebintActions');
    if(disableWebintActions){
      this.webintDisabledService.openWebintDisabledModal();
      return;
    }
    this.router.navigate([`/${ApplicationMainPageUrls.WEBINT}/history`]);
  }

  onNavigateToQuery(): void {
    this.queryService.isAutoFillQueries.next(false);
    this.dashboardService.componentsView.next(DashboardView.LOG);
  }

  onNavigateToTactical(): void {
    this.dashboardService.componentsView.next(DashboardView.LOG);
  }

  private isTargetsOrCasesOrInvestigationRoute(url: string): boolean {
    return (
      url === `/${ApplicationMainPageUrls.CORE}` ||
      url === `/${ApplicationMainPageUrls.CORE}/targets` ||
      url === `/${ApplicationMainPageUrls.CORE}/cases` ||
      url.includes('/targets') ||
      url.includes('/case') || 
      url.includes('/investigations')
    );
  }

  onMenuItemMouseOver(): void {
    this.subMenus.forEach(subMenu => subMenu.menuTrigger?.closeMenu());
  }

}
