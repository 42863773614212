import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Profile } from 'datalayer/models/social/profile';
import { RecoveryAccountDTO } from 'datalayer/services/social/recovery-account/recovery-account-dto';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { findIndex, first, remove, uniq } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { BaseService } from 'src/app/services/base.service';
import { ImageService } from 'src/app/services/image/image.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Query } from 'src/app/shared/models/query-item.model';
import { RecoveryAccount } from 'src/app/shared/models/recovery-account.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ARGENTINA_COUNTRY_CODE, isValidArgentinaMsisdn, transformCamelToSnakeRecursive, transformSnakeToCamel } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root'
})
export class TargetService extends BaseService {
  allTargets: TargetItem[];
  targetsChanged = new Subject<TargetItem[]>();
  selectedTarget = new BehaviorSubject<any>(null);
  allTargetQueries = new BehaviorSubject<TargetItem[]>([]);
  selectedTargetLastLocation = new Subject();
  paginatedTargets = new Subject();
  paginatedCaseTargets = new Subject();
  quarantineGeofenceData = new BehaviorSubject<Query>(null);
  targetHistory = new Subject<any>();
  shareTargetInfo: Subject<Partial<TargetItem>> = new Subject<Partial<TargetItem>>();
  private backendFakeNumberRegex = new RegExp(/99991111[0-3]{1}[0-9]{1}$/i);
  constructor(
    private httpClient: HttpClient,
    private queryService: QueryService,
    private profilerService: ProfilerService,
    protected router: Router,
    private imageService: ImageService,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService,

  ) {
    super(router, snackBar);
  }

  fetchAllTargets() {
    const params = {};
    params['limit'] = 1000;
    const subscription = this.httpClient
      .get<any>(`${this.url}/targets`, { params })
      .pipe(catchError(error => this.handleError(error)))
      .subscribe(data => {
        this.allTargets = transformSnakeToCamel(data.result);
        this.allTargetQueries.next(this.allTargets);
        subscription.unsubscribe();
      });
  }

  fetchPaginatedTargets({ limit = 1000, page = 1, filterArg = '' }, isCaseTargets = false, filterIfa = false) {
    const params = {};
    params['limit'] = limit;
    params['page'] = page;
    params['filterArg'] = filterArg;
    params['hasIfas'] = filterIfa;

    return this.httpClient.get<any>(`${this.url}/targets`, { params }).pipe(
      map(data => {
        if (isCaseTargets) {
          this.paginatedCaseTargets.next(transformSnakeToCamel(data));
        } else {
          this.paginatedTargets.next(transformSnakeToCamel(data));
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  getAllTargets(): Observable<TargetItem[]> {
    return this.allTargetQueries;
  }

  getTarget(targetId: string): Observable<TargetItem> {
    if (!targetId) {
      return;
    }
    return this.httpClient.get<any>(`${this.url}/targets/${targetId}`).pipe(
      map((target: any) => {
        //TODO: Need to this in the backend
        if (target.result?.imeis) {
          target.result.imeis.forEach((imei: any, index: number) => {
            if (imei === '') {
              target.result.imeis.splice(index, 1);
            }
          });
        }
        const dataToCamel = transformSnakeToCamel(target.result);
        return dataToCamel;
      }),
      catchError(error => this.handleError(error))
    );
  }

  clearSelectedTarget() {
    this.selectedTarget.next(null);
  }

  createTarget(target) {
    return this.httpClient
      .post<any>(`${this.url}/targets`, {
        alias: target.alias,
        names: [target.name],
        telnos: [target.phoneNumber],
        imsis: [target.imsi],
        tmsis: [target.tmsi],
        imeis: [target.imei],
        // color: target.pinColor,
        // photos: [target.avatar],
        assigned_users: [target.user],
        notify_on_capture: target.notifyOnCapture
      })
      .pipe(
        map(data => {
          this.allTargets.push(transformSnakeToCamel(data.result[0])); // TODO: Change BE to return the object instead of an array.
          this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          this.queryService.refreshLogQueries.next(true);
          return data;
        }),
        catchError(error => this.handleError(error))
      );
  }

  createTargetProfiler(target: any, createOptions: { createCase: boolean; addImProfiles: boolean }) {
    let targetPhotos: string[] = target.photos || [];
    targetPhotos = targetPhotos.concat(target.avatar && !targetPhotos.includes(target.avatar) ? [target.avatar] : []);

    return this.httpClient
      .post<any>(`${this.url}/targets`, {
        alias: target.alias,
        names: this.splitByComma(target.names),
        telnos: this.splitByComma(target.telnos),
        imsis: this.splitByComma(target.imsis),
        tmsis: this.splitByComma(target.tmsis),
        imeis: this.splitByComma(target.imeis),
        provider: target.provider || '',
        license_plates: this.splitByComma(target.licensePlates),
        emails: this.splitByComma(target.emails),
        usernames: uniq(target.usernames),
        user_ids: uniq(target.userIds),
        jobs: this.splitByComma(target.jobs),
        work_places: this.splitByComma(target.workPlaces),
        about: this.splitByComma(target.about),
        gender: target.gender || undefined,
        assigned_users: [target.user],
        photos: targetPhotos,
        country_code: target.countryCode || '',
        time_zone: target.timeZone || undefined,
        is_profiler: true,
        create_case: createOptions.createCase,
        countries: this.splitByComma(target.countries),
        social_profiles: target.socialProfiles || [],
        nationalId: target.nationalId || '',
        addresses: target.addresses,
        voterId: target.voterId || '',
        land_telnos: target.landTelnos || [],
        ifas: this.splitByComma(target.ifas),
        add_im_profiles: createOptions.addImProfiles,
        recovery_accounts: (target.recoveryAccounts || []).map((recoveryAccount: RecoveryAccount) => new RecoveryAccountDTO().fromModel(recoveryAccount)),
        date_of_birth: target.dateOfBirth || undefined,
        ife: this.splitByComma(target.ife),
        curp: this.splitByComma(target.curp),
        rfc: this.splitByComma(target.rfc),
        mother_names: this.splitByComma(target.motherNames),
        father_names: this.splitByComma(target.fatherNames),
        groups: this.splitByComma(target.groups),
        skills: this.splitByComma(target.skills),
        courses: this.splitByComma(target.courses),
        industry: this.splitByComma(target.industry),
        volunteering: this.splitByComma(target.volunteering),
        vehicle_id_number: this.splitByComma(target.vehicleIdNumber),
        vehicle_model: this.splitByComma(target.vehicleModel),
        vehicle_make: this.splitByComma(target.vehicleMake),
        vehicle_year: this.splitByComma(target.vehicleYear),
        fan_of: this.splitByComma(target.fanOf),
        property_type: this.splitByComma(target.propertyType),
        home_last_sale_date: this.splitByComma(target.homeLastSaleDate),
        other_tags: this.splitByComma(target.otherTags),
        associated_domain: this.splitByComma(target.associatedDomain),
        ip_addresses: this.splitByComma(target.ipAddresses),
        place_of_birth: this.splitByComma(target.placeOfBirth),
        nationalities: this.splitByComma(target.nationalities),
        hair_color: target.hairColor || undefined,
        eyes_color: target.eyesColor || undefined,
        height: target.height || undefined,
        weight: target.weight || undefined,
        marital_status: target.maritalStatus || undefined,
      })
      .pipe(
        map(data => {
          // TODO: Change BE to return the object instead of an array.
          this.fetchPaginatedTargets({ limit: 22, page: 1 });
          this.queryService.refreshLogQueries.next(true);
          return transformSnakeToCamel(data.result[0]);
        }),
        catchError(error => this.handleError(error))
      );
  }

  splitByComma(value: any): [] {
    if (value) {
      try {
        return value.split(',').map(e => e.trim());
      } catch (e) {
        return value;
      }
    } else {
      return [];
    }
  }

  splitByColumn(value: any): [] {
    if (value) {
      try {
        return value.split('|').map(e => e.trim());
      } catch (e) {
        return value;
      }
    } else {
      return [];
    }
  }

  // TODO: Use this to delete unused photos from db (needs testing)
  removeAvatar(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<any>(`${this.url}/files`, formData).pipe(
      map(fileResult => {
        return fileResult.result;
      }),
      catchError(error => this.handleError(error))
    );
  }

  editTarget(currentTarget, newTarget) {
    return this.httpClient
      .put<any>(`${this.url}/targets/${currentTarget.id}`, {
        alias: currentTarget.alias,
        names: [newTarget.name],
        telnos: [newTarget.phoneNumber],
        imsis: [newTarget.imsi],
        tmsis: [newTarget.tmsi],
        imeis: [newTarget.imei],
        // photos: [newTarget.avatar],
        // color: newTarget.pinColor
        assigned_users: [newTarget.user],
        notify_on_capture: newTarget.notifyOnCapture,
        user_ids: uniq(newTarget.userIds)
      })
      .pipe(
        map((data: any) => {
          const targetResult = transformSnakeToCamel(data.result);
          this.allTargets[findIndex(this.allTargets, ['id', currentTarget.id])] = targetResult;
          this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          this.selectedTarget.next(targetResult);
          this.queryService.refreshLogQueries.next(true);
        }),
        catchError(error => this.handleError(error))
      );
  }

  editProfileUrl(target: TargetItem) {
    return this.httpClient
      .put<any>(`${this.url}/targets/${target.id}`, {
        alias: target.alias,
        social_profiles: target.socialProfiles
      })
      .pipe(
        map((data: any) => {
          const dataToCamel = transformSnakeToCamel(data.result);
          this.profilerService.targetData.next(dataToCamel);
          return dataToCamel;
        }),
        catchError(error => this.handleError(error))
      );
  }

  editTargetPhotos(target: TargetItem, photos) {
    return this.httpClient
      .put<any>(`${this.url}/targets/${target.id}`, {
        alias: target.alias,
        photos
      })
      .pipe(
        map((data: any) => {
          const dataToCamel = transformSnakeToCamel(data.result);
          this.profilerService.targetData.next(dataToCamel);
          return dataToCamel;
        }),
        catchError(error => this.handleError(error))
      );
  }

  updateAboutSectionTarget(targetId: string, aboutData: TargetItem) {
    let params = this.getUpdateTargetParams(aboutData);
    params = { ...params, user_ids: uniq(aboutData.userIds), about: aboutData.about || [] };

    return this.httpClient
      .put<any>(`${this.url}/targets/${targetId}`, {
        ...params,
      })
      .pipe(
        map((data: any) => {
          const dataToCamel = transformSnakeToCamel(data.result);
          this.profilerService.targetData.next(dataToCamel);
          return dataToCamel;
        }),
        catchError(error => this.handleError(error))
      );
  }

  editTargetProfiler(currentTarget, newTarget, fromRelated = false) {
    let photos = newTarget.photos ? newTarget.photos : [];
    let users;

    if (newTarget.avatar && !currentTarget.photos.includes(newTarget.avatar)) {
      photos.unshift(newTarget.avatar);
    }

    photos = photos.concat(currentTarget.photos);

    if (newTarget.user) {
      users = [newTarget.user];
    } else {
      users = currentTarget.assigned_users;
    }

    Object.keys(currentTarget).forEach(key => {
      if (!newTarget[key] && newTarget[key] !== '') {
        newTarget[key] = currentTarget[key];
      }
    });

    let params = this.getUpdateTargetParams(newTarget);
    params = {
      ...params,
      about: this.splitByComma(newTarget.about),
      assigned_users: users,
      photos: photos,
      ifas: this.splitByComma(newTarget.ifas),
      social_profiles: newTarget.socialProfiles || [],
      color: newTarget.color || '',
      recovery_accounts: (newTarget.recoveryAccounts || []).map((recoveryAccount: RecoveryAccount) => new RecoveryAccountDTO().fromModel(recoveryAccount)),
    };

    return this.httpClient
      .put<any>(`${this.url}/targets/${currentTarget.id}`, {
        ...params,
      })
      .pipe(
        map((data: any) => {
          const dataToCamel = transformSnakeToCamel(data.result);
          if (fromRelated) {
            return dataToCamel;
          }
          this.profilerService.targetData.next(dataToCamel);
          if (this.allTargets) {
            this.allTargets[findIndex(this.allTargets, ['id', currentTarget.id])] = dataToCamel;
            this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          }
          return dataToCamel;
        }),
        catchError(error => this.handleError(error))
      );
  }

  editTargetAlias(currentTarget, newTarget) {
    let users;
    if (newTarget.user) {
      users = [newTarget.user];
    } else {
      users = currentTarget.assigned_users;
    }
    return this.httpClient
      .put<any>(`${this.url}/targets/${currentTarget.id}`, { alias: newTarget.alias, assigned_users: users })
      .pipe(
        map((data: any) => {
          const dataToCamel = transformSnakeToCamel(data.result);
          this.profilerService.targetData.next(dataToCamel);
          if (this.allTargets) {
            this.allTargets[findIndex(this.allTargets, ['id', currentTarget.id])] = dataToCamel;
            this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          }
          return dataToCamel;
        }),
        catchError(error => this.handleError(error))
      );
  }

  updateTarget(currentTarget, newTarget, addImProfiles: boolean = false) {
    return this.httpClient
      .put<any>(`${this.url}/targets/${currentTarget.id}`, {
        alias: currentTarget.alias,
        names: newTarget.names,
        telnos: newTarget.telnos,
        emails: newTarget.emails,
        imsis: newTarget.imsis,
        tmsis: newTarget.tmsis,
        imeis: newTarget.imeis,
        assigned_users: newTarget.assigned_users,
        notify_on_capture: newTarget.notifyOnCapture,
        assigned_cases: newTarget.assignedCases,
        add_im_profiles: addImProfiles,
        recovery_accounts: (newTarget.recoveryAccounts || []).map((recoveryAccount: RecoveryAccount) => new RecoveryAccountDTO().fromModel(recoveryAccount))
      })
      .pipe(
        map((data: any) => {
          const targetResult = transformSnakeToCamel(data.result);
          const targetIndex = findIndex(this.allTargets, ['id', currentTarget.id]);
          if (targetIndex && targetIndex >= 0) {
            this.allTargets[targetIndex] = targetResult;
            this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          }
        }),
        catchError(error => this.handleError(error))
      );
  }

  updateTargetIfas(target) {
    return this.httpClient
      .put<any>(`${this.url}/targets/${target.id}`, {
        alias: target.alias,
        ifas: this.splitByComma(target.ifas)
      })
      .pipe(
        map((data: any) => {
          const targetResult = transformSnakeToCamel(data.result);
          const targetIndex = findIndex(this.allTargets, ['id', target.id]);
          if (targetIndex && targetIndex >= 0) {
            this.allTargets[targetIndex] = targetResult;
            this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          }
        }),
        catchError(error => this.handleError(error))
      );
  }

  makeFavorite(target, favorite) {
    return this.httpClient
      .put<any>(`${this.url}/targets/${target.id}`, {
        alias: target.alias,
        favourite: favorite
      })
      .pipe(
        map((data: any) => {
          const targetResult = transformSnakeToCamel(data.result);
          const targetIndex = findIndex(this.allTargets, ['id', target.id]);
          if (targetIndex && targetIndex >= 0) {
            this.allTargets[targetIndex] = targetResult;
            this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          }
        }),
        catchError(error => this.handleError(error))
      );
  }

  removeTarget(currentTarget) {
    this.selectedTarget.next(null);
    return this.httpClient.delete<any>(`${this.url}/targets/${currentTarget.id}`).pipe(
      map((data: any) => {
        remove(this.allTargets, ['id', currentTarget.id]);
        this.queryService.refreshLogQueries.next(true);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getValidPhone(phoneNumberUtil: PhoneNumberUtil, value) {
    let target: PhoneNumber;
    try {
      target = phoneNumberUtil.parse(value);
    } catch (e) {
      return false;
    }

    const number = phoneNumberUtil.format(target, PhoneNumberFormat.E164);

    const countryCode = phoneNumberUtil.getRegionCodeForCountryCode(target.getCountryCode());
    if (countryCode === ARGENTINA_COUNTRY_CODE) {
      return isValidArgentinaMsisdn(value) ? number : null;
    }

    const validNumber = phoneNumberUtil.isValidNumber(target);
    if (validNumber || this.backendFakeNumberRegex.test(value)) {
      return number;
    }
    return false;

  }

  setTargetCdrRequestFlag(target, cdrRequest) {
    return this.httpClient
      .put<any>(`${this.url}/targets/${target.id}`, {
        alias: target.alias,
        requested_cdr: cdrRequest
      })
      .pipe(
        map((data: any) => {
          const targetResult = transformSnakeToCamel(data.result);
          this.allTargets[findIndex(this.allTargets, ['id', target.id])] = targetResult;
          this.targetsChanged.next(transformSnakeToCamel(this.allTargets.slice()));
          this.selectedTarget.next(targetResult);
        }),
        catchError(error => this.handleError(error))
      );
  }

  getDemoProfilePdf(pdfId) {
    return this.httpClient
      .get<any>(`${this.url}/demo-profiles/${pdfId}/download`, { responseType: 'blob' as 'json' })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => this.handleError(error))
      );
  }

  getFacebookProfile(url: string) {
    return this.httpClient.post<any>(`${this.url}/intel/resolve-url/facebook`, { url }).pipe(
      map((data: any) => {
        return transformSnakeToCamel(data);
      }),
      catchError(error => this.handleError(error))
    );
  }

  public getTargetQuarantineGeofence(targetId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/target/${targetId}/fenced-area`).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getHighRiskPeople(
    {
      limit = 5,
      page = 1,
      filterArg = '',
      sortKey = 'queryDate',
      sortOrder = -1,
      minDistance = 0,
      maxDistance = 10000
    },
    targetId: string
  ) {
    const params = {};
    params['limit'] = limit;
    params['page'] = page;
    params['filterArg'] = filterArg;
    params['sortOrder'] = sortOrder;
    params['sortKey'] = sortKey
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    params['minDistance'] = minDistance;
    params['maxDistance'] = maxDistance;

    return this.httpClient.get<any>(`${this.url}/target/${targetId}/poi`, { params }).pipe(
      map(data => {
        this.targetHistory.next(transformSnakeToCamel(data));
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  // TODO: Find better way
  getHighRiskPeopleNoLimit(
    { limit = 10000, page = 1, filterArg = '', sortKey = 'queryDate', sortOrder = -1 },
    targetId: string
  ) {
    const params = {};
    params['limit'] = limit;
    params['page'] = page;
    params['filterArg'] = filterArg;
    params['sortOrder'] = sortOrder;
    params['sortKey'] = sortKey
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    return this.httpClient.get<any>(`${this.url}/target/${targetId}/poi`, { params }).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getTargetPrimaryPhoto(target: TargetItem): string {
    const imageId = first(target.photos);
    if (!imageId) {
      return '/assets/static/images/no_photo.svg';
    }
    return this.imageService.getPhotoUrl(imageId, true) as string;
  }

  renewTarget(targetId: string): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/target/${targetId}/renew`, { targetId }, { observe: 'body' })
      .pipe(catchError(response => {
        const { status, message } = response;
        if (status === 401) {
          return throwError(this.translationService.translate(`Target has not been renewed, Not enough credits`));
        }
        return throwError(message);
      }));
  }

  renewMultipleTargets(targetIds: string[]): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/targets/renew`, { target_ids: targetIds }, { observe: 'body' })
      .pipe(catchError(response => {
        const { status, message } = response;
        if (status === 401) {
          return throwError(this.translationService.translate(`Target has not been renewed, Not enough credits`));
        }
        return throwError(message);
      }));
  }

  enableCallLogAnalysis(targetId: string): Observable<void> {
    return this.httpClient
      .put<any>(`${this.url}/target/${targetId}/call-log-renew`, { targetId })
      .pipe(catchError(error => this.handleError(error)));
  }

  getTargetsFromTelnos(telnos: string[]): Observable<{ [key: string]: TargetItem[] }> {
    return this.httpClient.post<any>(`${this.url}/target-telnos-filter`, { telnos }).pipe(
      map(data => {
        return data
      }),
      catchError(error => this.handleError(error))
    );
  }

  getTargetsFromData(telnos: string[], emails: string[], profileIds: string[], profileUrls: string[]): Observable<TargetItem[]> {
    return this.httpClient.post<any>(`${this.fastAPIurl}/targets/resolve`, { telnos, emails, profile_ids: profileIds, profile_links: profileUrls }).pipe(
      map(data => {
        return data
      }),
      catchError(error => this.handleError(error))
    );
  }

  getTargets(): Observable<TargetItem[]> {
    const params = {};
    params['limit'] = 1000;
    return this.httpClient.get<any>(`${this.url}/targets`, { params }).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  saveHiddenRelationsToTarget(profile: Profile, targetId: string): Observable<any> {
    if (profile) {
      const profiles = [];
      profiles.push(profile);
      return this.httpClient.post<any>(`${this.fastAPIurl}/entities/save-hidden-relations`, { profiles, target_id: targetId })
        .pipe(catchError(error => this.handleError(error)));
    }
  }

  saveRelationsToTarget(
    target: TargetItem,
    targetId: string,
  ): Observable<any> {
    return this.httpClient.post<any>(`${this.fastAPIurl}/entities/relations`, { target, target_id: targetId })
      .pipe(catchError(error => this.handleError(error)));
  }

  getUpdateTargetParams(target: TargetItem): Object {
    const params = {
      alias: target.alias,
      names: this.splitByComma(target.names),
      telnos: [...new Set(this.splitByComma(target.telnos))],
      imsis: [...new Set(this.splitByComma(target.imsis))],
      tmsis: this.splitByComma(target.tmsis),
      imeis: [...new Set(this.splitByComma(target.imeis))],
      license_plates: this.splitByComma(target.licensePlates),
      emails: this.splitByComma(target.emails),
      usernames: this.splitByComma(target.usernames),
      jobs: this.splitByComma(target.jobs),
      work_places: this.splitByComma(target.workPlaces),
      gender: target.gender || '',
      provider: target.provider || '',
      country_code: target.countryCode || '',
      addresses: target.addresses ? this.splitByColumn(target.addresses) : [],
      date_of_birth: target.dateOfBirth || '',
      place_of_birth: target.placeOfBirth ? this.splitByComma(target.placeOfBirth) : [],
      marital_status: target.maritalStatus || '',
      eyes_color: target.eyesColor || '',
      hair_color: target.hairColor || '',
      facial_hair: target.facialHair || '',
      social_insurance: target.socialInsurance || '',
      height: target.height || '',
      weight: target.weight || '',
      nationalities: target.nationalities ? this.splitByComma(target.nationalities) : [],
      land_telnos: this.splitByComma(target.landTelnos),
      race: target.race || '',
      countries: target.countries ? this.splitByComma(target.countries) : [],
      nationalId: target.nationalId || '',
      voterId: target.voterId || '',
      time_zone: target.timeZone || '',
      ife: this.splitByComma(target.ife),
      curp: this.splitByComma(target.curp),
      rfc: this.splitByComma(target.rfc),
      mother_names: this.splitByComma(target.motherNames),
      father_names: this.splitByComma(target.fatherNames),
      groups: this.splitByComma(target.groups),
      skills: this.splitByComma(target.skills),
      courses: this.splitByComma(target.courses),
      industry: this.splitByComma(target.industry),
      volunteering: this.splitByComma(target.volunteering),
      vehicle_id_number: this.splitByComma(target.vehicleIdNumber),
      vehicle_model: this.splitByComma(target.vehicleModel),
      vehicle_make: this.splitByComma(target.vehicleMake),
      vehicle_year: this.splitByComma(target.vehicleYear),
      fan_of: this.splitByComma(target.fanOf),
      property_type: this.splitByComma(target.propertyType),
      home_last_sale_date: this.splitByComma(target.homeLastSaleDate),
      other_tags: this.splitByComma(target.otherTags),
      associated_domain: this.splitByComma(target.associatedDomain),
      ip_addresses: this.splitByComma(target.ipAddresses),
    };
    return params;
  }

  public createTargetSocialProfileFromRelationProfile(profile: Profile, targetId: string) {
    return this.httpClient
      .post<any>(`${this.fastAPIurl}/entities/save-target-social-profile`, { profile: transformCamelToSnakeRecursive(profile), target_id: targetId })
      .pipe(catchError((error) => this.handleError(error)));
  }

}
