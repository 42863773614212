import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DarkwebDashboardComponent } from 'src/app/components/darkweb-dashboard/darkweb-dashboard.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { StatisticsComponent } from 'src/app/components/statistics/statistics.component';
import { StatisticsGrafanaComponent } from 'src/app/components/statistics-grafana/statistics-grafana.component';
import { TacticalDashboardComponent } from 'src/app/components/tactical/tactical-dashboard/tactical-dashboard.component';
import { VersionComponent } from 'src/app/components/version/version.component';
import { InvestigationModuleGuard } from 'src/app/services/application/investigation-module.guard';
import { UserOptionsGuard } from 'src/app/services/application/user-options.guard';
import { AuthGuard } from 'src/app/services/authentication/auth.guard';
import { RoleGuard } from 'src/app/services/roles/role-guard.service';
import { CanDeactivateGuard } from 'src/app/shared/services/can-deactivate.guard';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { AdintGuard } from './services/application/adint.guard';
import { AppGuard } from './services/application/app.guard';
import { LoginGuard } from './services/authentication/login.guard';
import { TermsOfUseGuard } from './services/authentication/terms-of-use.gauard';
import { ApplicationMainPageUrls } from './shared/models/application-main-page-urls.enum';
import { LandingPageGuard } from './services/dashboard/landing-page.guard';
import { DiscoveryGuard } from './services/application/discovery.guard';
import { CdrGuard } from "./services/application/cdr.guard";
import { OpenFilterInvestigationDialogGuard } from "./services/application/open-filter-investigation-dialog.guard";

const routes: Routes = [
  {
    path: '',
    canActivate: [AppGuard],

    children: [
      {
        path: '',
        children: [],
        pathMatch: 'full',
        canActivate: [LandingPageGuard],
      },
      {
        path: 'login',
        data: {
          title: 'Login',
        },
        component: LoginComponent,
        canActivate: [AppGuard, LoginGuard],
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
        data: {
          title: 'Terms of use',
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'discovery',
        component: DashboardComponent,
        data: {
          title: 'Discovery',
        },
        canActivate: [AuthGuard, TermsOfUseGuard, AdintGuard, DiscoveryGuard, CdrGuard],
      },
      {
        path: 'adint',
        data: {
          title: 'Adint',
        },
        loadChildren: () => import('./modules/ad-ids/ad-ids.module').then((m) => m.AdIdsModule),
        canActivate: [AuthGuard, TermsOfUseGuard, CdrGuard],
      },
      {
        path: 'admin',
        data: {
          title: 'Manager',
        },
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AuthGuard, TermsOfUseGuard, RoleGuard],
      },
      {
        path: 'user-settings',
        loadChildren: () => import('./modules/user-settings/user-settings.module').then((m) => m.UserSettingsModule),
        data: {
          title: 'User Settings',
        },
        canActivate: [AuthGuard, TermsOfUseGuard],
      },
      {
        path: 'user-options',
        data: {
          title: 'User Options',
        },
        loadChildren: () => import('./modules/user-options/user-options.module').then((m) => m.UserOptionsModule),
        canActivate: [AuthGuard, TermsOfUseGuard, UserOptionsGuard],
      },

      {
        path: ApplicationMainPageUrls.WEBINT,
        data: {
          title: 'Webint',
        },
        loadChildren: () => import('./modules/search-intel/search-intel.module').then((m) => m.SearchIntelModule),
        canActivate: [AuthGuard, TermsOfUseGuard, AdintGuard, CdrGuard],
      },
      {
        path: 'darkweb',
        data: {
          title: 'Dark Web',
        },
        component: DarkwebDashboardComponent,
        canActivate: [AuthGuard, TermsOfUseGuard, AdintGuard, CdrGuard],
      },
      {
        path: 'version',
        component: VersionComponent,
        canActivate: [CdrGuard],
      },
      {
        path: 'statistics',
        data: {
          title: 'Statistics',
        },
        component: StatisticsComponent,
        canActivate: [AuthGuard, TermsOfUseGuard],
      },
      {
        path: 'credits-usage-statistics',
        data: {
          title: 'Credits Usage Statistics',
        },
        component: StatisticsGrafanaComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tactical',
        data: {
          title: 'Tactical',
        },
        component: TacticalDashboardComponent,
        canActivate: [AuthGuard, TermsOfUseGuard, AdintGuard, CdrGuard],
      },
      {
        path: 'targets',
        data: {
          title: 'Cases and Targets',
        },
        redirectTo: ApplicationMainPageUrls.CORE,
      },
      {
        path: ApplicationMainPageUrls.CORE,
        data: {
          title: 'Core',
        },
        loadChildren: () => import('./modules/analysis/analysis.module').then((m) => m.AnalysisModule),
        canActivate: [AuthGuard, TermsOfUseGuard, AdintGuard],
      },
      {
        path: 'case-investigation',
        data: {
          title: 'Investigation',
        },
        loadChildren: () =>
          import('./modules/visual-investigation/visual-investigation.module').then((m) => m.VisualInvestigationModule),
        canActivate: [AuthGuard, TermsOfUseGuard, InvestigationModuleGuard, AdintGuard, OpenFilterInvestigationDialogGuard],
      },
      {
        path: 'case/:id',
        data: {
          title: 'Cases',
        },
        loadChildren: () => import('src/app/modules/case/case.module').then((m) => m.CaseModule),
        canActivate: [AuthGuard, TermsOfUseGuard, AdintGuard, CdrGuard],
      },
      {
        path: 'virtual-id',
        data: {
          title: 'Virtual ID',
        },
        loadChildren: () => import('src/app/modules/avatar/avatar.module').then((m) => m.AvatarModule),
        canActivate: [AuthGuard, TermsOfUseGuard, AdintGuard, CdrGuard],
      },
      {
        path: 'file-text-analysis',
        data: {
          title: 'file-text-analysis',
        },
        loadChildren: () =>
          import('src/app/modules/file-text-analysis/file-text-analysis.module').then((m) => m.FileTextAnalysisModule),
        canActivate: [AuthGuard, CdrGuard],
      },
      { path: '**', children:[], canActivate: [LandingPageGuard] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
  providers: [CanDeactivateGuard],
})
export class AppRoutingModule {}
