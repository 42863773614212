<nav
  id="app-navbar"
  class="navbar"
  [class.intelshield-theme]="isIntelshieldTheme"
  [class.genershield-theme]="isGenershieldTheme"
  #targetHeight
>
  <div class="containerFixed">
    <div class="wrapper">
      <mat-toolbar>
        <!-- LOGO -->
        <div class="clickable logo-container" *ngIf="navbarBehaviour" (click)="redirectTo()">
          <img *ngIf="!isGeolocTheme && !isWhiteTheme" class="navbar-logo" [src]="getLogo()" />
          <img *ngIf="isGeolocTheme" src="assets/static/images/geoloc_logo.svg" height="30" />
          <span
            *ngIf="!isGeolocTheme && !isWhiteTheme"
            class="logo-text"
            [innerHTML]="getLogoText()"
          ></span>
          <img *ngIf="isWhiteTheme" src="assets/static/images/world-white-logo.svg" height="30" />
        </div>

        <!-- NEW QUERY -->
        <div class="search-intel-query" *ngIf="navbarBehaviour && !isCDROnlyEnabled" [class.full-navbar]="fullNavbar">
          <app-search-intel-navbar
            (emitSearchLoader)="loaderShow($event)"
            (emitIntelNavbarClick)="fullNavbar = $event"
            [navBarIdentifier]="navBarIdentifier"
          >
          </app-search-intel-navbar>
        </div>
        <div class="loader-show" *ngIf="searchIntelLoader">
          <app-intel-loader></app-intel-loader>
        </div>
        <span class="nav-spacer"></span>

        <div
          *ngIf="!adintFlavourEnabled && !isGenershieldTheme"
          class="route-wrp flex-wrp"
          [class.move-navigations]="navbarBehaviour">
            <app-navbar-menu> </app-navbar-menu>
        </div>

        <div class="nav-actions flex-wrp">
          <div class="credit-guage nav-items flex-wrp">
            <app-credits-gauge></app-credits-gauge>
          </div>
          <div
            *ngIf="enableKnowledgeBase"
            class="nav-items flex-wrp"
            angulartics2On="click"
            [angularticsAction]="matomo.actions.knowledgeBase"
            [angularticsCategory]="matomo.categories.navBar"
            (click)="openKnowledgeBaseTab()"
            [matTooltip]="knowledgeBaseTooltip | translate"
          >
            <span>?</span>
          </div>
          <div
            class="nav-items flex-wrp platform-setting"
            [matMenuTriggerFor]="platformMenu"
            (click)="enableCurrentAccurateLocationSetting()"
          >
            <mat-icon class="icon">settings</mat-icon>
          </div>
          <div class="notifications nav-items flex-wrp">
            <app-notifications-list></app-notifications-list>
          </div>
          <div class="account-user nav-items flex-wrp">
            <button
              *ngIf="user"
              mat-icon-button
              class="user-icon account-btn"
              [matMenuTriggerFor]="userMenu"
            >
              <img src="./assets/static/images/user.svg" alt="account-user" />
            </button>
          </div>

          <mat-menu #userMenu="matMenu" class="user-menu assistant-font">
            <p class="flex-wrp user-name">
              <img src="./assets/static/images/user.svg" alt="account-user" width="30" />
              <span [matTooltip]="usernameDisplayValue" class="capitalize-none" *ngIf="usernameDisplayValue">{{ usernameDisplayValue }}</span>
            </p>
            <button mat-menu-item *ngIf="isAdmin || isSupport || isPower" routerLink="admin">
              {{ 'Manager' | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="theme !== 'UNLIMITED'"
              routerLink="user-options"
            >
              {{ 'User options' | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="enableUserSettingsV2"
              routerLink="user-settings"
            >
              {{ 'User settings' | translate }}
            </button>
            <button *ngIf="!isLocatingDisabled" mat-menu-item (click)="openTasksDialog('false')">
              {{ 'Tasks' | translate }}
            </button>
            <button *ngIf="!isLocatingDisabled" mat-menu-item routerLink="statistics">
              {{ 'Statistics' | translate }}
            </button>
            <button *ngIf="enableSupportRequest" mat-menu-item (click)="openSupportForm()">
              {{ 'Support request' | translate }}
            </button>
            <button mat-menu-item *ngIf="!isLocatingDisabled && enableUserManual" (click)="userManual()">
              {{ 'User manual' | translate }}
            </button>
            <button mat-menu-item (click)="logout()">{{ 'Sign out' | translate }}</button>
          </mat-menu>

          <mat-menu #platformMenu="matMenu" class="platform-menu user-menu assistant-font">
            <p class="flex-wrp user-name" *ngIf="!isLocatingDisabled">
              <span class="capitalize-none"> {{ 'Platform settings' | translate }} </span>
            </p>
            <div class="toggle-btn flex-wrp" *ngIf="!isLocatingDisabled">
              <p>
                {{ 'Always obtain accurate geolocation information when available' | translate }}.
              </p>
              <mat-slide-toggle
                [checked]="accurateLocation"
                (change)="changeAccurateLocation($event.checked)"
              >
              </mat-slide-toggle>
            </div>

            <div class="toggle-btn flex-wrp" *ngIf="!isLocatingDisabled && siteSettings">
              <p>{{ 'Always obtain the peer details when available' | translate }}.</p>
              <mat-slide-toggle
                [checked]="siteSettings.extractPeerInformation"
                (change)="
                  changeExtractPeerAction($event.checked, availableExtractPeerActions.getInfo)
                "
              >
              </mat-slide-toggle>
            </div>

            <div class="toggle-btn flex-wrp" *ngIf="!isLocatingDisabled && siteSettings">
              <p>
                {{ 'Always obtain the peer details and geolocate when available' | translate }}.
              </p>
              <mat-slide-toggle
                [checked]="siteSettings.extractPeerInformationAndLocate"
                (change)="
                  changeExtractPeerAction(
                    $event.checked,
                    availableExtractPeerActions.getInfoAndLocate
                  )
                "
              >
              </mat-slide-toggle>
            </div>
            <div class="toggle-btn flex-wrp" *ngIf="siteSettings" (click)="$event.stopPropagation();">
              <p>
                {{ 'Change language' | translate }}
              </p>
              <mat-form-field class="language-selector" appearance="outline" *ngIf="siteSettingsService.getStoreSiteSettings() | async as siteSettings">
                <mat-select
                  class="select-language"
                  [(value)]="translationService.language"
                  (selectionChange)="changeLanguage($event)"
                  (click)="$event.stopPropagation();"
                  [ngClass]="translationService.language"
                >
                  <mat-option *ngFor="let language of siteSettings.languages" [value]="language.value">
                    <img with="18" height="18" [src]="language?.image">
                    <span>{{language.display}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-menu>

          <div class="floating-msg capitalize-none" *ngIf="showfloatingMsg">
            <p>
              <span>{{ 'You can turn off' | translate }}</span>
              <b>{{
                'Always obtain accurate geolocation information when available' | translate
              }}</b>
              <span>{{ 'from Platform settings.' | translate }}</span>
            </p>
          </div>
          <div class="bg-blur" *ngIf="showfloatingMsg"></div>
        </div>
      </mat-toolbar>
    </div>
  </div>
</nav>

<ng-template #changePasswordTemplate>
  <button mat-menu-item (click)="openChangePasswordDialog()">
    {{ 'Change Password' | translate }}
  </button>
</ng-template>
