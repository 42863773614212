import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from "../../providers/app-config.service";

@Injectable({
  providedIn: 'root'
})
export class CdrGuard implements CanActivate {
  constructor(private appConfigService: AppConfigService, private router: Router) { }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isCDROnlyEnabled = this.appConfigService.getConfigVariable('isCDROnlyEnabled');

    if (isCDROnlyEnabled) {
      this.router.navigate(['/core/call-logs']);
    }

    return !isCDROnlyEnabled
  }
}
