import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { invert, merge } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getTranslations as getCountryTranslations } from 'src/app/services/translation/countries';
import { Dictionary } from 'src/app/services/translation/dictionary.model';
import { LanguageOptions } from 'src/app/shared/models/site-settings.model';
import { es as SpanishTranslations} from 'src/app/services/translation/translations/es';
import { fr as FrenchTranslations} from 'src/app/services/translation/translations/fr';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public language: string = LanguageOptions.EN;
  public languageChange: BehaviorSubject<string> = new BehaviorSubject(LanguageOptions.EN);

  constructor(
    private appConfigService: AppConfigService
  ) {
    this.appConfigService.anonConfigLoaded$.pipe(filter((loadedConfig: boolean) => !!loadedConfig)).subscribe(() => {
      this.language = this.appConfigService.getLanguage();
      this.languageChange.next(this.language);
    })
    this.appConfigService.authenticatedConfigLoaded$.pipe(filter((loadedConfig: boolean) => !!loadedConfig)).subscribe(() => {
      this.language = this.appConfigService.getLanguage();
      this.languageChange.next(this.language);
    })
  }

  private dictionary: { [key: string]: Dictionary; } = merge({}, getCountryTranslations(), {
    es: { languange: 'es', values: SpanishTranslations },
    fr: { languange: 'fr', values: FrenchTranslations }
  });

  /**
   *
   * @param key string
   * @param reverse boolean
   */
  translate(key: string, reverse = false, wrapMissing = true): string {
    if (this.language === 'en' || !this.dictionary[this.language]) {
      return key;
    }

    const dictionary = reverse
      ? invert(this.dictionary[this.language].values as any)
      : this.dictionary[this.language].values;
    return dictionary[key] || (!wrapMissing ? key : `[${key}]`);
  }

  /**
   *  Translate and interpolate given string with custom data.
   *  example: interpolate('my interpolated string with custom ${variable}', {variable: '__interpolated variable__'}) =>
   *   => 'my interpolated string with custom __interpolated variable__'
   * @param key string
   * @param data object
   */
  interpolate(key: string, data: { [key: string]: string }) {
    const translation = this.translate(key);

    return translation.replace(/#{(.*?)}/g, (match: string, clearString: string) => {
      return data[clearString] || match;
    });
  }
}
