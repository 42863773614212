import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EntityType } from '@trg-commons/data-models-ts';
import { CQRSBaseEvent, DataGroupingId, RequestStatus } from '@trg-commons/gio-data-models-ts';
import { catchError, combineLatest, map, mergeMap, Observable, switchMap } from 'rxjs';
import { BaseListenerService } from 'src/app/services/base-listener.service';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { InvestigationIntelRequest } from '../models/investigation-intel-request.interface';
import {
  GraphRouteToEntityType,
  OsintQueryBody,
  OsintQueryEvent,
  OsintQueryEventOperationRequest,
  OsintQueryGraphRequest,
} from '../models/osint-query.model';
import { InvestigationHelperService } from './investigation-helper.service';

@Injectable({
  providedIn: 'root',
})
export class InvestigationIntelService extends BaseListenerService {
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    protected wsManager: WebsocketManagerService,
    protected httpClient: HttpClient,
    private investigationHelperService: InvestigationHelperService
  ) {
    super(router, snackBar, wsManager, httpClient);
  }

  public postRequest(request: InvestigationIntelRequest): Observable<any> {
    const url = this.proxyApiUrl + '/intel/request';
    return this.postWithHeaders<CQRSBaseEvent<any>>(url, request).pipe(
      catchError((error) => {
        return this.handleError(error);
      }),
      switchMap((data) => {
        return this.createListenerForIntelRequests(data.correlationId);
      })
    );
  }

  public getRequest(requestStatus: RequestStatus, groupingId: DataGroupingId): Observable<any> {
    const url = `${this.proxyApiUrl}/intel/requests`;
    let params = new HttpParams();
    params = params.append('status', requestStatus);
    return this.httpClient.get<OsintQueryEventOperationRequest[]>(url, { params }).pipe(
      catchError((error) => {
        return this.handleError(error);
      }),
      map((events) => {
        const groupKey = Object.keys(groupingId)[0];
        return events.filter((event) => event['dataGroupingId'][groupKey] === groupingId[groupKey]);
      }),
      map((events) => {
        return events.map((event) => {
          if (requestStatus === RequestStatus.InProgress) {
            const platform = event.body.params.queryRoute.split('/')[1];
            this.investigationHelperService.updatePendingNodeActionsIds(
              `${event.body.params.queryParam.userId}@${platform}`,
              requestStatus
            );
            return this.createListenerForIntelRequests(event.correlationId);
          }
          const graphRequest = this.buildOsintQueryGraphRequest(event.body);
          return this.getGraph(graphRequest);
        });
      }),
      mergeMap((observables) => {
        return combineLatest(observables);
      })
    );
  }

  public getGraph(request: OsintQueryGraphRequest): Observable<any> {
    const url = this.proxyApiUrl + '/intel/graph';
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      params = params.append(key, request[key]);
    });

    return this.httpClient.get(url, { params }).pipe(
      catchError((error) => {
        return this.handleError(error);
      }),
      map((data) => {
        return { ...data, entityId: request.entityId };
      })
    );
  }

  private listenToNotifications(event: CQRSBaseEvent<OsintQueryEvent>): Observable<any> {
    switch (event.body.operationRequest.status) {
      case RequestStatus.Completed: {
        this.removeListener(event.correlationId);
        const graphRequest = this.buildOsintQueryGraphRequest(event.body.operationRequest.body);
        return this.getGraph(graphRequest);
      }
      default: {
        console.warn(
          'investigation intel service notification event status not handled: ',
          event.body.operationRequest.status,
          event
        );
        return new Observable();
      }
    }
  }

  private createListenerForIntelRequests(correlationId: string): Observable<any> {
    return this.createListener<CQRSBaseEvent<any>>(correlationId).pipe(
      switchMap((event) => {
        return this.listenToNotifications(event);
      })
    );
  }

  private buildOsintQueryGraphRequest(queryBody: OsintQueryBody): OsintQueryGraphRequest {
    const routeSplit = queryBody.params.queryRoute.split('/').reverse();
    return {
      entityId: queryBody.params.queryParam.userId,
      relationEntityTypeFrom: EntityType.Profile,
      relationEntityTypeTo: GraphRouteToEntityType[routeSplit[0]],
      source: (routeSplit[1] as Platform) || Platform.FACEBOOK,
      depth: 1,
      relationshipType: 'Authored',
    };
  }
}
