import { nodeTypes } from '../../link-analysis/shared/link-analysis.model';
import { SelectChipOption } from '../../search-intel/shared/multi-select-chip/multi-select-chip.model';
import { FtaNotificationState } from '../../visual-investigation/models/text-analysis-uploader.model';

export const extractedEntitiesAllowedTypes = [
  nodeTypes.PERSON,
  nodeTypes.EMAIL,
  nodeTypes.ORGANIZATION,
  nodeTypes.URL,
  nodeTypes.HASHTAG,
  nodeTypes.PLACE,
  nodeTypes.SOCIAL_PROFILE,
  nodeTypes.MSISDN,
  nodeTypes.LOCATION,
  nodeTypes.FILE,
  nodeTypes.EVENT_ENTITY,
];

export const NamedEntityTypes = {
  PEOPLE: 'People',
  ORGANIZATIONS: 'Organizations',
  PLACES: 'Places',
  NUMERICAL: 'Numerical',
  SUSPICIOUS: 'Suspicious',
  MISC: 'Miscellaneous',
  MSISDN: nodeTypes.MSISDN,
  EMAIL: nodeTypes.EMAIL,
  TIME: 'Time',
  DATE: 'Date',
  EVENT: 'Event',
  LAW: 'Law',
  URL: 'Url',
  CARDINAL: 'Cardinal',
  PRODUCT: 'Product',
  HASHTAG: 'Hashtag',
};
export interface WordWithId {
  id: string;
  text: string;
  color: string;
  weight?: number;
  entityType: string;
}

interface SentimentAnalysis {
  positive: number;
  negative: number;
  neutral: number;
}
export interface EntitiesTableItem {
  id: string;
  entityType: string;
  name: string;
  sentiment?: {
    positive: number;
    negative: number;
    neutral: number;
  };
  popularity?: number;
}

export const startingTableItemCount = 5;

export interface SuspiciousWordsItem {
  id: string;
  name: string;
  popularity?: number;
}

export interface WordToHighlight {
  wordText: string;
  entityType: string;
  id: string;
}

export interface PreviewDetails {
  fileId: string;
  wordsToHighlight: WordToHighlight[];
  nonAnalyzed: boolean;
}

export interface SubComponentsReadyData {
  wordCloud: WordWithId[];
  sentiment: SentimentAnalysis;
  entities: EntitiesTableItem[];
  suspiciousWords: SuspiciousWordsItem[];
  previewDetails: PreviewDetails;
  filters: SelectChipOption[];
}

export interface LayoutWidth {
  analysisWidth: string;
  wordCloudWidth: number ;
  wordCloudStep: number;
  previewWidth: string;
  previewZoom: string;
  previewHeight: string;
  componentWidth: string;
  modalWidth: string;
}

export enum FileAnalysisStatus {
  NOT_ANALYZED,
  ANALYSIS_IN_PROGRESS,
  ANALYZED,
}

export const jobStatusToFileAnalysisStatus = {
  [FtaNotificationState.DONE]: FileAnalysisStatus.ANALYZED,
  [FtaNotificationState.FAILED]: FileAnalysisStatus.NOT_ANALYZED,
  [FtaNotificationState.PENDING]: FileAnalysisStatus.ANALYSIS_IN_PROGRESS,
};

export const analysisBtnStateMap = {
  [FileAnalysisStatus.ANALYZED]: false,
  [FileAnalysisStatus.ANALYSIS_IN_PROGRESS]: false,
  [FileAnalysisStatus.NOT_ANALYZED]: true,
};

export enum LayoutNames {
  ANALYSIS_ONLY = 'analysis-only',
  TWOTHIRDS_ANALYSIS = 'twoThirds-analysis',
  HALF_ANALYSIS = 'half-analysis',
  PREVIEW_ONLY = 'preview-only',
}

const WordCloudWidths: { [key: string]: number } = {
  // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
  [LayoutNames.ANALYSIS_ONLY]: 0.99,
  [LayoutNames.TWOTHIRDS_ANALYSIS]: 0.7,
  [LayoutNames.HALF_ANALYSIS]: 0.5,
  [LayoutNames.PREVIEW_ONLY]: 0,
};

export const AnalysisLayoutWidths: { [key: string]: LayoutWidth } = {
  [LayoutNames.ANALYSIS_ONLY]: {
    componentWidth: '70vw',
    analysisWidth: '100%',
    wordCloudWidth: WordCloudWidths[LayoutNames.ANALYSIS_ONLY],
    wordCloudStep: 15,
    previewZoom: '0%',
    previewWidth: '0%',
    previewHeight: '0vh',
    modalWidth: '55vw',
  },
  [LayoutNames.TWOTHIRDS_ANALYSIS]: {
    componentWidth: '90vw',
    analysisWidth: '75%',
    wordCloudWidth: WordCloudWidths[LayoutNames.TWOTHIRDS_ANALYSIS],
    wordCloudStep: 15,
    previewZoom: '45%',
    previewWidth: '40vw',
    previewHeight: '185vh',
    modalWidth: '90vw',
  },
  [LayoutNames.HALF_ANALYSIS]: {
    componentWidth: '90vw',
    analysisWidth: '70%',
    wordCloudWidth: WordCloudWidths[LayoutNames.HALF_ANALYSIS],
    wordCloudStep: 15,
    previewZoom: '90%',
    previewWidth: '50vw',
    previewHeight: '65vw',
    modalWidth: '90vw',
  },
  [LayoutNames.PREVIEW_ONLY]: {
    componentWidth: '70%',
    analysisWidth: '0%',
    wordCloudWidth: WordCloudWidths[LayoutNames.PREVIEW_ONLY],
    wordCloudStep: 0,
    previewZoom: '100%',
    previewWidth: '80vw',
    previewHeight: '75vh',
    modalWidth: '55vw',
  },
};

export type FtaTableItem = EntitiesTableItem | SuspiciousWordsItem;
