import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CQRSBaseEvent } from '@trg-commons/gio-data-models-ts';
import { map, Observable, Subject, switchMap, take } from 'rxjs';
import { BaseService } from './base.service';
import { WebsocketManagerService } from './websocket/websocket-manager.service';

@Injectable({
  providedIn: 'root',
})
export class BaseListenerService extends BaseService {
  public listeners: Map<string, Subject<any>> = new Map();
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    protected wsManager?: WebsocketManagerService,
    protected httpClient?: HttpClient
  ) {
    super(router, snackBar);
    this.initializeListeners();
  }

  protected getConnectionId(): Observable<string> {
    return this.wsManager.getServerTsConnection().pipe(map((socket) => socket.id));
  }

  protected buildHttpHeaders(connectionId: string): HttpHeaders {
    return new HttpHeaders().append('Ws-Connection-Id', connectionId);
  }

  protected postWithHeaders<T>(url: string, request: unknown): Observable<T> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap((connectionId) => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.httpClient.post<T>(url, request, { headers: headers });
      })
    );
  }

  protected createListener<T>(correlationId: string): Observable<T> {
    const subject = new Subject<T>();
    this.listeners.set(correlationId, subject);
    return subject.asObservable();
  }

  protected removeListener(correlationId: string): void {
    this.listeners.delete(correlationId);
  }

  protected putWithHeaders<T>(url: string, request: unknown): Observable<T> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap((connectionId) => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.httpClient.put<T>(url, request, { headers: headers });
      })
    );
  }

  protected initializeListeners() {
    this.wsManager.getServerTsConnection().subscribe(ws => {
      ws.on('message', (data: CQRSBaseEvent<any>) => {
        const listener = this.listeners.get(data.correlationId);
        if (!listener) {
          return;
        }
        listener.next(data);
      });
    });
  }
}
