import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Animations } from '../../animations/animations';
import { BaseDialogComponent } from '../../classes/base-dialog.component';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { IQueryParams } from "../../../modules/search-intel/models/query-params.interface";
import { FileTextAnalysisService } from "../../../modules/file-text-analysis/services/file-text-analysis.service";
import { AnalysisService } from "../../../modules/analysis/shared/services/analysis.service";
import { AnalysisUtilService } from "../../../modules/analysis/shared/services/analysis-util.service";
import { forkJoin, Observable } from "rxjs";
import {
  CallLogsListResponseModel,
  CLPaginatedResponse
} from "../../../modules/analysis/shared/models/targets-list-request.model";
import { map, tap } from "rxjs/operators";
import { head } from "lodash";
import { CaseInvestigationService } from "../../../modules/analysis/shared/services/case-investigations.service";
import { DialogCustomService } from "../../services/dialog-custom.service";
import { Region } from "../../models/region.model";
import { Country } from "../../models/country.model";
import { DataGatheringService } from 'src/app/modules/visual-investigation/services/data-gathering.service';
import { NavigationHistoryService } from "../../services/navigation-history.service";
import { CdrQueryParamsService } from "../../services/cdr-query-params.service";
import { KEYS_ARRAY } from "../../constants/constant";
import { TargetItem } from '../../models/target-item.model';
import { TargetService } from 'src/app/services/target/target.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-filter-investigation-dialog',
  templateUrl: './filter-investigation-dialog.component.html',
  styleUrls: ['./filter-investigation-dialog.component.scss'],
  animations: [Animations.slideInOut],
})
export class FilterInvestigationDialogComponent extends BaseDialogComponent implements OnInit {
  public country = new FormControl();
  regions = new FormControl();
  public regionList: Region[] = [];
  public countries: Country[] = [];
  public msisdnArray = [];
  isDirectOrTopChecked = false; 
  filters = this.formBuilder.group({
    'directConnection': false,
    'topAssociates': false,
    'internationalInteractions': false,
    'country':  new FormControl(),
    'region':this.regions,
    type: new FormGroup({
        'voice': new FormControl(false),
        'sms': new FormControl(false),
        'data': new FormControl(false),
    }),
    'msisdns': []
  })
  idsArray;

  constructor(
    public dialogRef: MatDialogRef<FilterInvestigationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    router: Router,
    private route: ActivatedRoute,
    private fileTextAnalysisService: FileTextAnalysisService,
    private analysisService: AnalysisService,
    private analysisUtilService: AnalysisUtilService,
    private caseInvestigationService: CaseInvestigationService,
    private dialogCustomService: DialogCustomService,
    private formBuilder: FormBuilder,
    private dataGatherinService: DataGatheringService,
    private navigationHistoryService: NavigationHistoryService,
    private cdrQueryParamsService: CdrQueryParamsService,
    private targetService: TargetService
  ) {
    super(dialogRef, router);
  }

  ngOnInit() {
      let keysArray = KEYS_ARRAY;

      let data = this.cdrQueryParamsService.getData();

      for(let key of keysArray) {
          if(data && data.queryParams && data.queryParams[key]) {
              this.idsArray = data.queryParams[key];
              break;
          }
      }

      if (!this.idsArray) { // if idsArray is undefined or null, make it an empty array
          this.idsArray = [];
      }
      if (data?.queryParams?.targetIds) {
        this.getTargets(data.queryParams.targetIds);
      }

    let requests = this.idsArray.map((id) =>
      this.fetchCallLogsRequestData(id).pipe(
        tap(value => {
          if (value?.msisdns?.length) {
            this.msisdnArray.push(value?.msisdns[0]); // push the msisdn into the array
            this.filters.patchValue({ msisdns: this.msisdnArray });
          }
        })
      )
    );

      forkJoin(requests).subscribe(
          () => {
              this.fileTextAnalysisService.fetchCountries(this.msisdnArray).subscribe(
                  (countries: Country[]) => {
                      this.countries = countries;
                  },
                  (error) => {
                      console.log('Error fetching countries:', error);
                  }
              );

              this.fileTextAnalysisService.fetchRegions(this.msisdnArray).subscribe(
                  (regions: Region[]) => {
                      this.regionList = regions;
                  },
                  (error) => {
                      console.log('Error fetching regions:', error);
                  }
              );
          }
      );

    this.caseInvestigationService.getCaseInvestigationById('').subscribe();

    // this.dataGatherinService.getCallLogsFilters$().subscribe()
  }

  public onCheckChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.isDirectOrTopChecked = true;
    } else {
      this.isDirectOrTopChecked = this.filters.value.topAssociates || this.filters.value.directConnection ? true : false
    }
    const types = {
      voice: this.isDirectOrTopChecked,
      sms: this.isDirectOrTopChecked,
      data: this.filters.value.type.data
    }
    this.filters.patchValue({type: types});
  }

  private fetchCallLogsRequestData(requestId: string): Observable<CallLogsListResponseModel> { // TODO: we have the same in another place, should be used from one place
    const requestBody = this.analysisUtilService.buildClRequestPayload({
      requestId: requestId,
      pageSize: '1',
      pageNumber: '1',
      createdBy: []
    });
    return this.analysisService.getCallLogsList(requestBody).pipe(
        map((resp) => {
          const callLogsData: CLPaginatedResponse = head(resp);
          return callLogsData?.paginatedResults[0];
        })
    );
  }

  private getTargets(targetIds: string[]) {
    targetIds.forEach((id) => {
      this.subscriptions.push(
        this.targetService.getTarget(id).subscribe((target: TargetItem) => {
            this.msisdnArray.push(...target.telnos);
            this.filters.patchValue({msisdns: this.msisdnArray});
        })
      );
    });
  }

    navigateToInvestigation(queryParams: IQueryParams): void {
        const currentRoute = this.navigationHistoryService.getCurrentUrl();

        if (currentRoute.includes('/case-investigation')) {
            this.dialogCustomService.dialogClosed();
            this.dialogRef.close();
            return;
        }

        this.dialogCustomService.closeDialogAndNavigate('/case-investigation', { ...this.data.queryParams || queryParams });
        this.dialogRef.close();
    }


    onLoadSelectedData() {
    this.dataGatherinService.callLogsFilters$.next(this.filters.value)
    this.navigateToInvestigation(this.data);
  }



  onSkip() {
    this.dataGatherinService.callLogsFilters$.next({})
    this.navigateToInvestigation(this.data);
  }
}
