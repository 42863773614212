import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    FilterInvestigationDialogComponent
} from "../../shared/components/filter-investigation-dialog/filter-investigation-dialog.component";
import { DialogCustomService } from "../../shared/services/dialog-custom.service";
import { AppConfigService } from "../../providers/app-config.service";
import { CdrQueryParamsService, } from "../../shared/services/cdr-query-params.service";

@Injectable({
    providedIn: 'root',
})
export class OpenFilterInvestigationDialogGuard implements CanActivate {
    private isCDROnlyEnabled: boolean;

    constructor(
        public dialog: MatDialog,
        private dialogCustomService: DialogCustomService,
        private configService: AppConfigService,
        private cdrQueryParamsService: CdrQueryParamsService) {
        this.isCDROnlyEnabled = this.configService.getConfigVariable('isCDROnlyEnabled');
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if(!this.isCDROnlyEnabled) {
            return true;
        }

        this.cdrQueryParamsService.formatAndStoreData(route.queryParams);

        this.dialog.open(FilterInvestigationDialogComponent, {
            data: {
                queryParams: this.cdrQueryParamsService.getData()
            }
        });

        return this.dialogCustomService.dialogClosed().pipe(
            map(() => true),
        );
    }
}
